import React, { useContext } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Icon,
} from '@mui/material';
import Button from 'components/Button';
import { useModal } from 'components/Modals';
import { LançamentosContext } from 'contexts/LançamentosContext';

const DeleteModal = ({ item, callback }) => {
  const { closeModal } = useModal();
  const { deleteLançamento, deleteLoading } = useContext(LançamentosContext);

  const onSubmit = () =>
    deleteLançamento({
      params: { financeiro_historico_id: item?.id },
      cb: () => {
        closeModal();
        if (callback) {
          callback();
        }
      },
    });

  return (
    <>
      <DialogTitle>Confirmar Estorno</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText>
          <Icon sx={{ fontSize: 150 }}>undo</Icon>
        </DialogContentText>
        <DialogContentText>
          Deseja realmente estornar a baixa?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} loading={deleteLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteModal;
