import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { PagamentosBaixadosContext } from 'contexts/PagamentosBaixadosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import DeleteModal from '../Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const PagamentosBaixados = () => {
  const rotina = 'PagamentosBaixados';
  const titulo = 'Pagamentos Baixados';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { pagamentosBaixados, getPagamentosBaixados, getLoading } = useContext(
    PagamentosBaixadosContext
  );
  const { control, getValues, reset } = useForm({
    defaultValues: pagamentosBaixados?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getPagamentosBaixados({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: pagamentosBaixados?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: pagamentosBaixados?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={pagamentosBaixados?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Recibos',
      icon: 'print',
      action: (selected) => {
        if (!Boolean(selected?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        getURLRelatorio({
          data: {
            codigo: 'RCB0000001',
            financeiro_id: JSON.stringify(
              selected?.map((s) => s?.financeiro_id)
            ),
          },
          cb: loadGrid,
        });
      },
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: 'Estornar',
      icon: 'delete',
      action: ({ row }) =>
        openModal(<DeleteModal item={row} callback={loadGrid} />),
    },
    {
      name: 'Recibo Completo',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: {
            codigo: 'RCB0000002',
            financeiro_movimento_id: row?.financeiro_movimento_id,
          },
          cb: loadGrid,
        }),
    },
    {
      name: 'Demonstrativo do Movimento Financeiro',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: {
            codigo: 'FINMOV0001',
            mov: row?.financeiro_movimento_id,
          },
          cb: loadGrid,
        }),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={pagamentosBaixados?.colunas}
      />
      <Card>
        <Grid
          grid={pagamentosBaixados}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={pagamentosBaixados} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default PagamentosBaixados;
