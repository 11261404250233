import { createContext, useState } from 'react';
import api from 'services/api';

export const PendênciasContext = createContext();

export const PendênciasProvider = ({ children }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [pendencias, setPendencias] = useState([]);

  const getPendencias = async (id) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Financeiro/Pendencias/${id}`);
      setPendencias(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postPendencias = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Financeiro/Pendencias`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postBaixa = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Financeiro/Pendencias/Baixa', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postBoletos = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Financeiro/Pendencias/Boletos`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteBoletos = async ({ data, cb }) => {
    try {
      setDeleteLoading(true);
      await api.post(`/Financeiro/Pendencias/DeleteBoletos`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const getBoleto = async ({ id, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Financeiro/Pendencias/Boleto/${id}`);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getBoletosBaixados = async ({ cb }) => {
    try {
      setPostLoading(true);
      await api.get('/Financeiro/Pendencias/Boletos/Baixa');
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <PendênciasContext.Provider
      value={{
        postLoading,
        postBaixa,
        getLoading,
        getPendencias,
        pendencias,
        postPendencias,
        postBoletos,
        deleteLoading,
        deleteBoletos,
        getBoleto,
        getBoletosBaixados,
      }}
    >
      {children}
    </PendênciasContext.Provider>
  );
};
