import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  dtemis: {
    i: null,
    f: null,
  },
  dtlanc: {
    i: null,
    f: null,
  },
  conta_id: [],
  valor: '',
  conciliado: null,
  detalhes: '',
};

export const LançamentosContext = createContext();

export const LançamentosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [contas, setContas] = useState([]);
  const [lançamentos, setLançamentos] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtlanc', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const getLançamentos = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: lançamentos?.filter,
        page: lançamentos?.page,
        size: lançamentos?.size,
        order: lançamentos?.order,
        ...payload,
      });
      setLançamentos((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getLançamento = async ({ id, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Financeiro/Lancamentos/${id}`);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getContas = async () => {
    try {
      setGetLoading(true);
      setContas([]);
      const { data } = await api.get('/Financeiro/Lancamentos/Contas');
      setContas(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postLançamento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Financeiro/Lancamentos`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteLançamento = async ({ params, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Financeiro/Lancamentos`, { params });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const postTransferencia = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Financeiro/Lancamentos/Transferencia`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const loadOfx = async ({ data, cb }) => {
    try {
      setGetLoading(true);
      const { data: res } = await api.post(
        '/Financeiro/Lancamentos/Ofx',
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      if (cb) {
        cb(res);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postLançamentos = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Financeiro/Lancamentos/Multiple`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <LançamentosContext.Provider
      value={{
        getLoading,
        getLançamento,
        getLançamentos,
        lançamentos,
        getContas,
        contas,
        postLoading,
        postLançamento,
        deleteLançamento,
        deleteLoading,
        postTransferencia,
        loadOfx,
        postLançamentos,
      }}
    >
      {children}
    </LançamentosContext.Provider>
  );
};
