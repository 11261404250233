import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
import Contas from 'views/Contas';
//DESPESAS
import Despesas from 'views/Despesas';
import DespesasGerar from 'views/Despesas/Gerar';
//PREVISÕES
import Previsoes from 'views/Previsões';
import PrevisoesGerar from 'views/Previsões/Gerar';
//LANÇAMENTOS
import Lançamentos from 'views/Lançamentos';
import LançamentosGerar from 'views/Lançamentos/Gerar';
import LançamentosDetalhes from 'views/Lançamentos/Detalhes';
import LançamentosConciliar from 'views/Lançamentos/Conciliar';
//PENDENCIAS
import PendênciasBaixa from 'views/Pendências/Baixa';
import PendênciasCheques from 'views/Pendências/Cheques';
import PendênciasPagamentos from 'views/Pendências/Pagamentos';
import PendênciasRecebimentos from 'views/Pendências/Recebimentos';
import PendênciasHaveres from 'views/Pendências/Haveres';
import PendênciasHaveresGerar from 'views/Pendências/Haveres/Gerar';
//Baixas
import BaixasCheques from 'views/Baixas/Cheques';
import BaixasPagamentos from 'views/Baixas/Pagamentos';
import BaixasRecebimentos from 'views/Baixas/Recebimentos';
import BaixasHaveres from 'views/Baixas/Haveres';
//RELATÓRIOS
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';
//DOCUMENTO
import Documento from 'views/Documento';
import DocumentoItem from 'views/Documento/Item';
import DocumentoAprovações from 'views/Documento/Aprovações';
//OUTLETS
import OutletDocumento from './Outlets/Documento';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Contas" element={<Contas />} />
    <Route path="Despesas" element={<Outlet />}>
      <Route path="" element={<Despesas />} />
      <Route path="Gerar" element={<DespesasGerar />} />
    </Route>
    <Route path="Previsoes" element={<Outlet />}>
      <Route path="" element={<Previsoes />} />
      <Route path="Gerar" element={<PrevisoesGerar />} />
    </Route>
    <Route path="Lancamentos" element={<Outlet />}>
      <Route path="" element={<Lançamentos />} />
      <Route path="Gerar" element={<LançamentosGerar />} />
      <Route path="Detalhes" element={<LançamentosDetalhes />} />
      <Route path="Conciliar/:hash" element={<LançamentosConciliar />} />
    </Route>
    <Route path="Pendencias" element={<Outlet />}>
      <Route path="Cheques" element={<PendênciasCheques />} />
      <Route path="Pagamentos" element={<PendênciasPagamentos />} />
      <Route path="Recebimentos" element={<PendênciasRecebimentos />} />
      <Route path="Haveres" element={<Outlet />}>
        <Route path="" element={<PendênciasHaveres />} />
        <Route path="Gerar" element={<Outlet />}>
          <Route path=":type" element={<PendênciasHaveresGerar />} />
        </Route>
      </Route>
      <Route path="Baixa" element={<Outlet />}>
        <Route path=":type" element={<PendênciasBaixa />}>
          <Route path=":id" element={<PendênciasBaixa />} />
        </Route>
      </Route>
    </Route>
    <Route path="Baixas" element={<Outlet />}>
      <Route path="Cheques" element={<BaixasCheques />} />
      <Route path="Pagamentos" element={<BaixasPagamentos />} />
      <Route path="Recebimentos" element={<BaixasRecebimentos />} />
      <Route path="Haveres" element={<BaixasHaveres />} />
    </Route>
    <Route path="Relatorios" element={<Outlet />}>
      <Route path="" element={<Relatórios />} />
      <Route path="Filtros" element={<RelatóriosFiltros />} />
    </Route>
    <Route path="Documento" element={<OutletDocumento />}>
      <Route path=":id" element={<Documento />} />
      <Route path="Item" element={<DocumentoItem />} />
      <Route path="Aprovacoes" element={<DocumentoAprovações />} />
    </Route>
  </Routes>
);

export default RoutesTree;
