const styles = {
  lineLabel: {
    flex: 2,
  },
  lineValue: {
    flex: 5,
  },
};

export default styles;
