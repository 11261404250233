import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  entidade: [],
  dtvenc: {
    i: null,
    f: null,
  },
  valor: '',
  vendedor: [],
  filial: [],
  dtemissao: {
    i: null,
    f: null,
  },
  observacao: '',
  banco: [],
  agencia: '',
  conta: '',
  cheque: '',
  nome: '',
  alinea: [],
};

export const ChequesPendentesContext = createContext();

export const ChequesPendentesProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [chequesPendentes, setChequesPendentes] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getChequesPendentes = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: chequesPendentes?.filter,
        page: chequesPendentes?.page,
        size: chequesPendentes?.size,
        order: chequesPendentes?.order,
        ...payload,
      });
      setChequesPendentes((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <ChequesPendentesContext.Provider
      value={{
        getLoading,
        getChequesPendentes,
        chequesPendentes,
      }}
    >
      {children}
    </ChequesPendentesContext.Provider>
  );
};
