import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { ChequesPendentesContext } from 'contexts/ChequesPendentesContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const ChequesPendentes = () => {
  const rotina = 'ChequesPendentes';
  const titulo = 'Cheques Pendentes';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { chequesPendentes, getChequesPendentes, getLoading } = useContext(
    ChequesPendentesContext
  );
  const { control, getValues, reset } = useForm({
    defaultValues: chequesPendentes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getChequesPendentes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: chequesPendentes?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: chequesPendentes?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={chequesPendentes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Baixar Pendências',
      icon: 'price_check',
      action: (cheques) => {
        if (!Boolean(cheques?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        navigate('/app/Pendencias/Baixa/Cheques', { state: { cheques } });
      },
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      show: ({ row }) => Boolean(row?.documento_id),
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: 'Demonstrativo do Movimento Financeiro',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: {
            codigo: 'FINMOV0001',
            mov: row?.financeiro_movimento_id,
          },
          cb: loadGrid,
        }),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={chequesPendentes?.colunas}
      />
      <Card>
        <Grid
          grid={chequesPendentes}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          totalizer="valor"
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={chequesPendentes} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default ChequesPendentes;
