import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { GridContext } from 'contexts/GridContext';
import { PrevisoesContext } from 'contexts/PrevisoesContext';
import TableContainer from 'components/TableContainer';
import Loader from 'components/Loader';
import Button from 'components/Button';
import moment from 'moment';

const PrevisãoModal = ({ item, onSubmit }) => {
  const [selected, setSelected] = useState(null);
  const { prevs, getPrevs, getLoading } = useContext(PrevisoesContext);
  const { deleteLoading } = useContext(GridContext);

  useEffect(() => {
    getPrevs({
      filial_id: item?.filial_id,
      cadastro_id: item?.entidade_id,
    });
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h6">
          Selecione uma previsão para baixá-la
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Descrição</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell>Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prevs?.map((item) => (
                <TableRow key={item?.id?.toString()}>
                  <TableCell>
                    <Radio
                      color="primary"
                      size="small"
                      checked={selected === item?.id}
                      onChange={() => setSelected(item?.id)}
                    />
                  </TableCell>
                  <TableCell>{item?.descricao}</TableCell>
                  <TableCell>
                    {Boolean(item?.dtvenc) &&
                      moment(item?.dtvenc).isValid() &&
                      moment(item?.dtvenc).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {item?.valor?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            onSubmit({
              rotina: 'Previsões',
              id: selected,
            })
          }
          loading={deleteLoading}
        >
          Baixar
        </Button>
      </Grid>
    </Grid>
  );
};

export default PrevisãoModal;
