import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { QueryStats, Visibility } from '@mui/icons-material';
import { dropBoolean } from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import api from 'services/api';
import styles from '../styles';
import mock from '../mock';

const Documento = ({
  drops,
  loading,
  onSubmit,
  documento = {},
  disableActions,
}) => {
  const defaultValues = {
    //GERAL
    id: documento?.id,
    especie_id: documento?.especie_id || null,
    natureza_operacao_id: documento?.natureza_operacao_id || null,
    filial_id: documento?.filial_id || null,
    cadastro_id: documento?.cadastro_id || null,
    dtemissao: documento?.dtemissao || null,
    documento: documento?.documento || '',
    contribuinte_id: documento?.contribuinte_id || null,
    usofinal: documento?.usofinal || null,
    celocal_id: documento?.celocal_id || null,
    //ENTRADA
    serie: documento?.DocumentoEntrada?.serie || '',
    dtentrada: documento?.DocumentoEntrada?.dtentrada || null,
    custo: documento?.DocumentoEntrada?.custo || null,
    ped_fornec: documento?.DocumentoEntrada?.ped_fornec,
    //ADICIONAL
    observacao: documento?.observacao || '',
    dadosadc: documento?.dadosadc || '',
  };
  const { control, watch, setValue, handleSubmit } = useForm({ defaultValues });
  const {
    tipoComplemento,
    showEntidade,
    showContribuinte,
    showLocalEstoque,
    isEntrada,
    isPedido,
    isFatura,
  } = mock({
    documento,
  });

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = {
        entidade_id: v?.value,
        tipo: tipoComplemento,
      };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.contribuinte_id) {
        setValue('contribuinte_id', data?.contribuinte_id);
      }
      if (data?.usofinal) {
        setValue('usofinal', data?.usofinal);
      }
    } else {
      setValue('contribuinte_id', 3);
      setValue('usofinal', 'NAO');
    }
  };

  const onConfirm = (values) => {
    let data = {
      Documento: {
        id: documento?.id,
        users_id: documento?.users_id,
        financeiro_movimento_id: documento?.financeiro_movimento_id,
        conferido: documento?.conferido,
        gerado: documento?.gerado,
        conversao: documento?.conversao,
        natureza_operacao_id: values?.natureza_operacao_id,
        especie_id: values?.especie_id,
        filial_id: values?.filial_id,
        documento: values?.documento,
        cadastro_id: values?.cadastro_id,
        contribuinte_id: values?.contribuinte_id,
        usofinal: values?.usofinal,
        dtemissao: values?.dtemissao,
        celocal_id: values?.celocal_id,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
    };
    if (isEntrada) {
      data = {
        ...data,
        Entrada: {
          documento_id: documento?.id,
          serie: values?.serie,
          dtentrada: values?.dtentrada,
          custo: values?.custo,
          ped_fornec: values?.ped_fornec,
        },
      };
    }
    onSubmit(data);
  };

  return (
    <>
      <Card title="Informações Gerais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="especie_id"
              control={control}
              label="Espécie"
              options={drops?.EspecieDocumento}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="natureza_operacao_id"
              control={control}
              label="Natureza da Operação"
              options={drops?.NaturezaOperacao}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          {showEntidade && (
            <Grid item xs={10}>
              <VirtualDrop
                name="cadastro_id"
                control={control}
                label="Entidade"
                options={drops?.Entidade}
                onValueChange={onChangeEntidade}
                refresh="Entidade"
              />
            </Grid>
          )}
          {showEntidade && (
            <Grid
              item
              xs={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  window.open(
                    `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                      'cadastro_id'
                    )}`
                  )
                }
                disabled={!Boolean(watch('cadastro_id'))}
              >
                <Visibility fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  window.open(
                    `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                      'cadastro_id'
                    )}`
                  )
                }
                disabled={!Boolean(watch('cadastro_id'))}
              >
                <QueryStats fontSize="small" />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12} sm={showContribuinte ? 3 : 4}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12} sm={showContribuinte ? 3 : 4}>
            <Input
              name="documento"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          {showContribuinte && (
            <Grid item xs={12} sm={3}>
              <Dropdown
                name="contribuinte_id"
                control={control}
                label="Contribuinte"
                options={drops?.Contribuinte}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={showContribuinte ? 3 : 4}>
            <Dropdown
              name="usofinal"
              control={control}
              label="Uso Final"
              options={dropBoolean}
            />
          </Grid>
          {showLocalEstoque && (
            <Grid item xs={12}>
              <Dropdown
                name="celocal_id"
                control={control}
                label="Local de Estoque"
                options={drops?.LocalEstoque?.filter(
                  (f) =>
                    !Boolean(f?.filial_id) ||
                    f?.filial_id === watch('filial_id')
                )}
              />
            </Grid>
          )}
        </Grid>
      </Card>
      {isEntrada && (
        <Card title="Entrada" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={isPedido && isFatura ? 3 : isPedido || isFatura ? 4 : 6}
            >
              <Input name="serie" control={control} label="Série" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={isPedido && isFatura ? 3 : isPedido || isFatura ? 4 : 6}
            >
              <InputMask
                name="dtentrada"
                control={control}
                label="Data de Entrada"
                type="date"
              />
            </Grid>
            {isFatura && (
              <Grid item xs={12} sm={isPedido ? 3 : 4}>
                <Dropdown
                  name="custo"
                  control={control}
                  label="Custo"
                  options={dropBoolean}
                />
              </Grid>
            )}
            {isPedido && (
              <Grid item xs={12} sm={isFatura ? 3 : 4}>
                <Input
                  name="ped_fornec"
                  control={control}
                  label="Documento de Compra"
                />
              </Grid>
            )}
          </Grid>
        </Card>
      )}
      <Card title="Informações Adicionais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
          {!disableActions && (
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="outlined"
                loading={loading}
                onClick={handleSubmit(onConfirm)}
              >
                Salvar
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default Documento;
