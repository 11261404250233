import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const PagamentoModal = ({ item, onSubmit }) => {
  const defaultValues = {
    dtvenc: item?.dtvenc || null,
    valor: item?.valor || '',
    forma_pagto_id: item?.forma_pagto_id || null,
    observacao: item?.observacao || '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="dtvenc"
          control={control}
          label="Data de Vencimento"
          type="date"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drops?.FormaDePagamento?.filter(
            (f) => f?.modulo !== 'VENDAS' && f?.modulo !== 'CHEQUES'
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="observacao"
          control={control}
          label="Observação"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default PagamentoModal;
