import { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const ItemModal = ({ item: defaultValues, onSubmit }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="planoconta_id"
          control={control}
          label="Plano de Conta"
          options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="ccusto_id"
          control={control}
          label="Centro de Custo"
          options={drops?.Ccusto}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="aplicacao_id"
          control={control}
          label="Aplicação"
          options={drops?.Aplicacao}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="frota_id"
          control={control}
          label="Frota"
          options={drops?.Frota}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ItemModal;
