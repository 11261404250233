export const dropBoolean = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

export const dropIntervalo = [
  { value: 'DIARIO', label: 'DIÁRIO' },
  { value: 'SEMANAL', label: 'SEMANAL' },
  { value: 'QUINZENAL', label: 'QUINZENAL' },
  { value: 'MENSAL', label: 'MENSAL' },
  { value: 'ANUAL', label: 'ANUAL' },
];

export const dropStatusDocumento = [
  { value: 'CANCELADO', label: 'CANCELADO' },
  { value: 'CONCLUIDO', label: 'CONCLUÍDO' },
  { value: 'EXPEDICAO', label: 'EXPEDIÇÃO' },
  { value: 'FINALIZADO', label: 'FINALIZADO' },
  { value: 'PARALISADO', label: 'PARALISADO' },
  { value: 'PENDENTE', label: 'PENDENTE' },
];

export const dropOrigem = [
  { value: 'CLIENTE', label: 'CLIENTE' },
  { value: 'FORNECEDOR', label: 'FORNECEDOR' },
];

export const dropNTributado = [
  { value: 'ISENTO', label: 'ISENTO' },
  { value: 'OUTROS', label: 'OUTROS' },
  { value: 'NENHUM', label: 'NENHUM' },
];
