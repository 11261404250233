import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('entidade') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="entidade"
            control={control}
            label="Entidade"
            options={drops?.Entidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('dtvenc') && (
        <Grid item xs={6}>
          <InputMask
            name="dtvenc.i"
            control={control}
            label="Data de Vencimento Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtvenc') && (
        <Grid item xs={6}>
          <InputMask
            name="dtvenc.f"
            control={control}
            label="Data de Vencimento Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtlanc') && (
        <Grid item xs={6}>
          <InputMask
            name="dtlanc.i"
            control={control}
            label="Data de Lançamento Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtlanc') && (
        <Grid item xs={6}>
          <InputMask
            name="dtlanc.f"
            control={control}
            label="Data de Lançamento Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input name="documento" control={control} label="Nº do Documento" />
        </Grid>
      )}
      {checkDisplay('parcela') && (
        <Grid item xs={12}>
          <Input
            name="parcela"
            control={control}
            label="Parcela"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('valor') && (
        <Grid item xs={12}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
      )}
      {checkDisplay('valor_outros') && (
        <Grid item xs={12}>
          <InputMask
            name="valor_outros"
            control={control}
            label="Valor Outros"
          />
        </Grid>
      )}
      {checkDisplay('forma_pagto') && (
        <Grid item xs={12}>
          <Dropdown
            name="forma_pagto"
            control={control}
            label="Forma de Pagamento"
            options={drops?.FormaDePagamento?.filter(
              (f) => f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
            )}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('vendedor') && (
        <Grid item xs={12}>
          <Dropdown
            name="vendedor"
            control={control}
            label="Vendedor"
            options={drops?.EntidadeTipo?.filter(
              (f) => f?.tipo === 'VENDEDOR' || f?.tipo === 'REPRESENTANTE'
            )}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('filial') && (
        <Grid item xs={12}>
          <Dropdown
            name="filial"
            control={control}
            label="Filial"
            options={drops?.Filial}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('observacao') && (
        <Grid item xs={12}>
          <Input name="observacao" control={control} label="Observação" />
        </Grid>
      )}
      {checkDisplay('origem') && (
        <Grid item xs={12}>
          <Input name="origem" control={control} label="Origem" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
