const styles = {
  card: {
    marginTop: '1em',
  },
  semiCard: {
    width: '100%',
  },
  button: {
    margin: '1em 0',
  },
  formControl: {
    display: 'flex',
    py: '1em',
  },
  radio: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  label: {
    flex: 1,
  },
};

export default styles;
