import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const Desconto = ({ drops, valor, onSubmit }) => {
  const forma_pagto_id =
    drops?.FormaDePagamento?.find(
      (f) =>
        f?.modulo === 'CHEQUES' &&
        f?.label === 'DESCONTO' &&
        f?.modalidade === '90-SEM PAGAMENTO'
    )?.value || null;
  const defaultValues = {
    valor: valor || '',
    forma_pagto_id,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const onAddLancamento = (values) => {
    if (Boolean(values?.valor <= 0)) {
      return toastWarning('Valor inválido');
    }
    const data = {
      ...values,
      forma_pagto: findOnArray(
        values?.forma_pagto_id,
        drops?.FormaDePagamento,
        'label'
      ),
    };
    onSubmit(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputMask name="valor" control={control} label="Valor" disabled />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drops?.FormaDePagamento}
          disabled
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onAddLancamento)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default Desconto;
