import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { summarizer } from 'utils/functions';
import { DropsContext } from 'contexts/DropsContext';
import { PendênciasContext } from 'contexts/PendênciasContext';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import moment from 'moment';

const RestituiçãoModal = ({ itens = [], operacao, callback }) => {
  const defaultValues = {
    forma_pagto_id: null,
    dtlanc: moment().format('YYYY-MM-DD'),
    cxconta_id: null,
    planoconta_id: itens[0]?.planoconta_restituicao || null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postBaixa, postLoading } = useContext(PendênciasContext);

  const valor = summarizer(itens, 'valor_pendente');

  const dropFormaPagto =
    operacao === 'PAGAMENTOS'
      ? drops?.FormaDePagamento?.filter(
          (f) => f?.modulo !== 'VENDAS' && f?.modulo !== 'CHEQUES'
        )
      : operacao === 'RECEBIMENTOS'
      ? drops?.FormaDePagamento?.filter(
          (f) => f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
        )
      : [];

  const dropPlanoconta =
    operacao === 'PAGAMENTOS'
      ? drops?.Planoconta?.filter(
          (f) => f?.['Operação'] === 'DEBITO' && f?.operacional === 'SIM'
        )
      : operacao === 'RECEBIMENTOS'
      ? drops?.Planoconta?.filter(
          (f) => f?.['Operação'] === 'CREDITO' && f?.operacional === 'SIM'
        )
      : [];

  const onSubmit = (values) => {
    const data = {
      dtlanc: values?.dtlanc,
      financeiros: itens?.map((item) => ({
        documento: item?.documento,
        entidade_id: item?.entidade_id,
        forma_pagto_id: item?.forma_pagto_id,
        id: item?.id,
        parcela: item?.parcela,
        valor: item?.valor_pendente,
      })),
      lancamentos: [
        {
          valor,
          forma_pagto_id: values?.forma_pagto_id,
          cxconta_id: values?.cxconta_id,
          planoconta_id: values?.planoconta_id,
        },
      ],
      operacao,
    };
    postBaixa({
      data,
      cb: () => {
        if (callback) {
          callback();
        }
        closeDialog();
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" align="center">
          Total a restituir:{' '}
          {Math.abs(valor)?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={dropFormaPagto}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="dtlanc"
          control={control}
          label="Data de Lançamento"
          type="date"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="cxconta_id"
          control={control}
          label="Conta"
          options={drops?.Cxconta}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="planoconta_id"
          control={control}
          label="Plano de Conta"
          options={dropPlanoconta}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default RestituiçãoModal;
