import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import { DropsContext } from 'contexts/DropsContext';
import { LançamentosContext } from 'contexts/LançamentosContext';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import moment from 'moment';

const TransferênciaModal = ({ item, callback }) => {
  const defaultValues = {
    dtlanc: moment().format('YYYY-MM-DD'),
    valor: '',
    planoconta_id: null,
    cxconta_id_org: item?.id,
    cxconta_id_dest: null,
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postTransferencia, postLoading } = useContext(LançamentosContext);
  const { drops } = useContext(DropsContext);

  const onSubmit = (data) =>
    postTransferencia({
      data,
      cb: () => {
        closeModal();
        if (callback) {
          callback();
        }
      },
    });

  return (
    <>
      <DialogTitle>Transferência entre contas</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} sm={4}>
            <InputMask
              type="date"
              name="dtlanc"
              control={control}
              label="Data de Lançamento"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="valor" control={control} label="Valor" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="planoconta_id"
              control={control}
              label="Plano de Contas"
              options={drops?.Planoconta?.filter(
                (f) => Boolean(f?.contacp_id) && f?.Operação === 'CREDITO'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="cxconta_id_org"
              control={control}
              label="Conta Origem"
              options={drops?.Cxconta}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="cxconta_id_dest"
              control={control}
              label="Conta Destino"
              options={drops?.Cxconta}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default TransferênciaModal;
