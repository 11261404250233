import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import InputMask from 'components/InputMask';

const EditModal = ({ item, onSubmit }) => {
  const defaultValues = {
    id: item?.id,
    valor_total: item?.valor || '',
    type: 'DESC',
  };
  const { closeModal } = useModal();
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  return (
    <>
      <DialogTitle>Editar Pendência</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <InputMask
              name="valor_total"
              control={control}
              label="Valor Total"
              onChange={() => setValue('type', 'DESC')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                value={watch('type')}
                onChange={(e) => setValue('type', e?.target?.value)}
              >
                <FormControlLabel
                  value="DESC"
                  control={<Radio />}
                  label="Aplicar Diferença no Desconto / Acréscimo"
                />
                <FormControlLabel
                  value="PARC"
                  control={<Radio />}
                  label="Baixar Parcialmente"
                  disabled={watch('valor_total') >= item?.valor_max}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default EditModal;
