import React from 'react';
import Deposito from './Deposito';
import CaixaBanco from './CaixaBanco';
import Cartão from './Cartão';
import ChequeLista from './ChequeLista';
import Cheque from './Cheque';
import Compensação from './Compensação';
import Refinanciamento from './Refinanciamento';
import Haver from './Haver';
import Desconto from './Desconto';

const Tabs = (props) => {
  switch (props?.tab) {
    case 0:
      if (props?.isCheque) {
        return <Deposito {...props} />;
      } else {
        return <CaixaBanco {...props} />;
      }
    case 1:
      return <Cartão {...props} />;
    case 2:
      if (props?.isPagamento) {
        return <ChequeLista {...props} />;
      } else {
        return <Cheque {...props} />;
      }
    case 3:
      return <Compensação {...props} />;
    case 4:
      return <Refinanciamento {...props} />;
    case 5:
      return <Haver {...props} />;
    case 6:
      return <Desconto {...props} />;
    case 7:
      return <Cheque {...props} />;
    default:
      return null;
  }
};

export default Tabs;
