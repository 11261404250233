import { useLayoutEffect, useRef, useState } from 'react';
import { PieChart } from '@mui/x-charts';
import { Box, Typography } from '@mui/material';

const Pizza = ({ data = [], title }) => {
  const ref = useRef(null);
  const [size, setSize] = useState({});

  useLayoutEffect(() => {
    setSize({
      width: ref.current.offsetWidth,
      height: ref.current.offsetWidth / 3,
    });
  }, []);

  return (
    <Box ref={ref}>
      {Boolean(data?.length) ? (
        <PieChart
          series={[{ data }]}
          width={size?.width || 0}
          height={size?.height || 0}
        />
      ) : (
        <Typography textAlign="center" variant="body2">
          Nenhum registro encontrado
        </Typography>
      )}
    </Box>
  );
};

export default Pizza;
