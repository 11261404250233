import React, { useContext, useEffect, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from '@mui/material';
import { PendênciasContext } from 'contexts/PendênciasContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import Loader from 'components/Loader';
import styles from './styles';
import moment from 'moment';

const BoletoModal = ({ id }) => {
  const { closeModal } = useModal();
  const [boleto, setBoleto] = useState(null);
  const { getBoleto, getLoading } = useContext(PendênciasContext);

  const Line = ({ value, label }) => (
    <Box display="flex" alignItems="center">
      <Typography sx={styles?.lineLabel} variant="subtitle2">
        {label}:
      </Typography>
      <Typography sx={styles?.lineValue} variant="body2">
        {value || '-'}
      </Typography>
    </Box>
  );

  useEffect(() => {
    if (id) getBoleto({ id, cb: (data) => setBoleto(data) });
  }, []);

  return (
    <>
      <DialogTitle>Dados do Boleto</DialogTitle>
      <DialogContent>
        {getLoading ? (
          <Loader />
        ) : (
          <Box>
            <Line label="Nº do Boleto" value={boleto?.boleto} />
            <Line
              label="Data da Geração"
              value={
                boleto?.dtgeracao &&
                moment(boleto?.dtgeracao)?.format('DD/MM/YYYY')
              }
            />
            <Line
              label="Data de Vencimento"
              value={
                boleto?.dtvenc && moment(boleto?.dtvenc)?.format('DD/MM/YYYY')
              }
            />
            <Line
              label="Limite de Pagamento"
              value={
                boleto?.dtlimitepagamento &&
                moment(boleto?.dtlimitepagamento)?.format('DD/MM/YYYY')
              }
            />
            <Line label="Situação de Pagamento" value={boleto?.pagamento} />
            <Line label="Valor do Título" value={boleto?.valortitulo} />
            <Line label="Valor Pago" value={boleto?.valorpago} />
            <Line label="Valor do Juros" value={boleto?.valorjuros} />
            <Line label="Valor da Multa" value={boleto?.valormulta} />
            <Line label="Valor do Desconto" value={boleto?.valordesconto} />
            <Line label="Valor do Abatimento" value={boleto?.valorabatimento} />

            <Box sx={{ mt: '1em' }}>
              <Typography variant="subtitle2" color="primary">
                Boleto registrado no banco{' '}
                {Boolean(boleto?.usuarioregistro)
                  ? `por ${boleto?.usuarioregistro}`
                  : ''}{' '}
                {Boolean(boleto?.dtregistro)
                  ? `em ${moment(boleto?.dtregistro)?.format(
                      'DD/MM/YYYY [às] HH:mm'
                    )}`
                  : ''}
              </Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default BoletoModal;
