import { useLayoutEffect, useRef, useState } from 'react';
import { BarChart } from '@mui/x-charts';
import { Box, Typography } from '@mui/material';
import { defaultTheme } from 'utils/theme';

const Bar = ({ data = [], dataKey }) => {
  const ref = useRef(null);
  const [size, setSize] = useState({});
  const { primary, grey } = defaultTheme?.palette;

  useLayoutEffect(() => {
    setSize({ width: ref.current.offsetWidth, height: 400 });
  }, []);

  return (
    <Box ref={ref} sx={{ display: 'flex', alignItems: 'center' }}>
      {Boolean(data?.length) ? (
        <BarChart
          dataset={data}
          xAxis={[{ scaleType: 'band', dataKey: 'nome' }]}
          series={[
            {
              dataKey: dataKey + '_interval_1',
              label: 'Até 5 Dias',
              color: primary?.dark,
            },
            {
              dataKey: dataKey + '_interval_2',
              label: 'Entre 5 e 15 Dias',
              color: primary?.light,
            },
            {
              dataKey: dataKey + '_interval_3',
              label: 'Entre 15 e 30 Dias',
              color: primary?.main,
            },
            {
              dataKey: dataKey + '_interval_4',
              label: 'Mais de 30 Dias',
              color: grey[1000],
            },
          ]}
          width={size?.width}
          height={size?.height || 0}
        />
      ) : (
        <Typography textAlign="center" variant="body2">
          Nenhum registro encontrado
        </Typography>
      )}
    </Box>
  );
};

export default Bar;
