import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const ItemModal = ({ item, onSubmit }) => {
  const { drops } = useContext(DropsContext);

  const defaultValues = {
    descricao: item?.descricao || '',
    quantidade: item?.quantidade || '',
    preco: item?.preco || '',
    planoconta_id: item?.planoconta_id || null,
    ccusto_id: item?.ccusto_id || null,
    aplicacao_id: item?.aplicacao_id || null,
    frota_id: item?.frota_id || null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {item?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input name="descricao" control={control} label="Descrição" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask name="preco" control={control} label="Preço" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="planoconta_id"
          control={control}
          label="Plano de Conta"
          options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="ccusto_id"
          control={control}
          label="Centro de Custo"
          options={drops?.Ccusto}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="aplicacao_id"
          control={control}
          label="Aplicação"
          options={drops?.Aplicacao}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="frota_id"
          control={control}
          label="Frota"
          options={drops?.Frota}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ItemModal;
