import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { HaveresPendentesContext } from 'contexts/HaveresPendentesContext';
import { toastWarning } from 'utils/toast';
import { useModal, useDialog } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import RestituiçãoModal from '../Modals/RestituiçãoModal';
import Filter from './filter';
import mock from './mock';

const HaveresPendentes = () => {
  const rotina = 'HaveresPendentes';
  const titulo = 'Haveres Pendentes';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { haveresPendentes, getHaveresPendentes, getLoading } = useContext(
    HaveresPendentesContext
  );
  const { control, getValues, reset } = useForm({
    defaultValues: haveresPendentes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getHaveresPendentes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar Haver para Cliente',
      icon: 'add',
      action: () => navigate('/app/Pendencias/Haveres/Gerar/Cliente'),
    },
    {
      name: 'Adicionar Haver para Fornecedor',
      icon: 'add',
      action: () => navigate('/app/Pendencias/Haveres/Gerar/Fornecedor'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: haveresPendentes?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: haveresPendentes?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={haveresPendentes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Restituir Haveres',
      icon: 'currency_exchange',
      action: (selected) => {
        const isEqual = selected?.every(
          (e) => e?.origem === selected[0]?.origem
        );
        if (!Boolean(selected?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (!isEqual) {
          return toastWarning('Selecione somente haveres da mesma origem');
        }
        const operacao =
          selected[0]?.origem === 'CLIENTE'
            ? 'RECEBIMENTOS'
            : selected[0]?.origem === 'FORNECEDOR'
            ? 'PAGAMENTOS'
            : null;
        openDialog(
          <RestituiçãoModal
            operacao={operacao}
            callback={loadGrid}
            itens={selected}
          />,
          'Restituir Haveres'
        );
      },
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={haveresPendentes?.colunas}
      />
      <Card>
        <Grid
          grid={haveresPendentes}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          totalizer="valor_pendente"
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={haveresPendentes} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default HaveresPendentes;
