import { Chip, Typography } from '@mui/material';
import moment from 'moment';

const mock = [
  {
    field: 'dtvenc',
    renderCell: ({ value }) =>
      moment().isAfter(moment(value)) ? (
        <Chip
          size="small"
          label={moment(value).format('DD/MM/YYYY')}
          color="secondary"
          sx={{ flex: 1 }}
        />
      ) : (
        <Typography variant="body2">
          {moment(value).format('DD/MM/YYYY')}
        </Typography>
      ),
  },
  {
    field: 'valor',
    valueGetter: ({ value }) =>
      value?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
  {
    field: 'valor_baixado',
    valueGetter: ({ value }) =>
      value?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
  {
    field: 'valor_pendente',
    valueGetter: ({ value }) =>
      value?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
  {
    field: 'valor_outros',
    valueGetter: ({ value }) =>
      value?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
  {
    field: 'dtemissao',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
];

export default mock;
