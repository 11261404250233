import React, { useContext, useState } from 'react';
import { DialogActions, DialogContent, Grid, Icon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import {
  findOnArray,
  summarizer,
  validarCPF,
  validarCNPJ,
} from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog, useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';
import moment from 'moment';

const ChequeModal = ({
  isRecebimento,
  isPagamento,
  isCheque,
  item,
  onSubmit,
}) => {
  const { closeDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const [cheques, setCheques] = useState(item?.cheques);
  const { drops } = useContext(DropsContext);

  const drop = drops?.FormaDePagamento?.filter((f) => {
    if (isPagamento) {
      return (
        f?.modalidade === '02-CHEQUE' &&
        f?.modulo !== 'VENDAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isRecebimento) {
      return (
        f?.modalidade === '02-CHEQUE' &&
        f?.modulo !== 'COMPRAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isCheque) {
      return f?.modalidade === '02-CHEQUE' && f?.modulo === 'CHEQUES';
    } else {
      return f?.modalidade === '02-CHEQUE';
    }
  });

  const onAddBanda = async (e) => {
    const banda = e?.target?.value?.replace(/\D/g, '');
    if (banda?.length === 30) {
      const banco = banda?.substring(0, 3);
      const agencia = banda?.substring(3, 7);
      const cheque = banda?.substring(11, 17);
      const cc = banda?.substring(23, 28);
      const verificador3 = banda?.substring(28, 29);

      let conta = '';
      if (Boolean(cc)) conta = conta + cc;
      if (Boolean(cc) && Boolean(verificador3)) conta = conta + '-';
      if (Boolean(verificador3)) conta = conta + verificador3;

      const banco_id =
        drops?.EntidadeTipo?.filter(
          (f) => f?.tipo === 'INSTITUICAO FINANCEIRA'
        )?.find((s) => s?.Código == banco)?.value || null;

      setValue('banda', banda);
      setValue('banco_id', banco_id);
      setValue('conta', conta);
      setValue('agencia', agencia);
      setValue('cheque', cheque);

      const { data } = await api.get(`/Financeiro/Pendencias/Cheque`, {
        params: { banda },
      });

      if (data?.nome) {
        setValue('nome', data?.nome || '');
      }
      if (data?.tipo_documento_id) {
        setValue('tipo_documento_id', data?.tipo_documento_id || null);
      }
      if (data?.numdoc) {
        setValue('numdoc', data?.numdoc || '');
      }
    }
  };

  const onAddLancamento = () => {
    const data = {
      cheques,
      valor: summarizer(cheques, 'valor'),
      forma_pagto_id: cheques[0]?.forma_pagto_id,
      forma_pagto: findOnArray(cheques[0]?.forma_pagto_id, drop, 'label'),
    };
    onSubmit(data);
    closeDialog();
  };

  const Modal = ({ cheque }) => {
    const defaultValues = {
      valor: cheque?.valor || '',
      forma_pagto_id: item?.forma_pagto_id || null,
      banda: cheque?.banda || '',
      banco_id: cheque?.banco_id || null,
      agencia: cheque?.agencia || '',
      conta: cheque?.conta || '',
      cheque: cheque?.cheque || '',
      nome: cheque?.nome || '',
      cidade: cheque?.cidade || '',
      dtemissao: cheque?.dtemissao || null,
      dtvenc: cheque?.dtvenc || null,
      tipo_documento_id: cheque?.tipo_documento_id || null,
      numdoc: cheque?.numdoc || '',
      observacao: cheque?.observacao || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });

    const onAddCheque = (values) => {
      if (Boolean(values?.valor <= 0)) {
        return toastWarning('Valor inválido');
      }
      if (values?.tipo_documento_id === 1 && !validarCNPJ(values?.numdoc)) {
        return toastWarning('CNPJ inválido');
      }
      if (values?.tipo_documento_id === 2 && !validarCPF(values?.numdoc)) {
        return toastWarning('CPF inválido');
      }
      if (cheque) {
        setCheques((prev) =>
          prev?.map((p) => (p?.id === cheque?.id ? { ...p, ...values } : p))
        );
      } else {
        setCheques((prev) => [...prev, { id: prev?.length, ...values }]);
      }
      closeModal();
    };

    return (
      <>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputMask name="valor" control={control} label="Valor" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="forma_pagto_id"
                control={control}
                label="Forma de Pagamento"
                options={drop}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="banda"
                control={control}
                label="Banda"
                onBlur={onAddBanda}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="banco_id"
                control={control}
                label="Banco"
                options={drops?.EntidadeTipo?.filter(
                  (f) => f?.tipo === 'INSTITUICAO FINANCEIRA'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="agencia"
                control={control}
                label="Agência"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input name="conta" control={control} label="Conta" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="cheque"
                control={control}
                label="Nº do Cheque"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input name="nome" control={control} label="Nome" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input name="cidade" control={control} label="Cidade" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="dtemissao"
                control={control}
                label="Data de Emissão"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="dtvenc"
                control={control}
                label="Data de Vencimento"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="tipo_documento_id"
                control={control}
                label="Tipo de Documento"
                options={drops?.TipoDocumento}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name="numdoc"
                control={control}
                label="Nº do Documento"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="observacao"
                control={control}
                label="Observação"
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onAddCheque)}>Confirmar</Button>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => openModal(<Modal />, 90)}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={cheques}
          columns={[
            {
              field: 'nome',
              headerName: 'Nome',
              flex: 3,
              sortable: false,
            },
            {
              field: 'cheque',
              headerName: 'Nº do Cheque',
              flex: 2,
              sortable: false,
            },
            {
              field: 'dtvenc',
              headerName: 'Data de Vencimento',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                value && moment(value).format('DD/MM/YYYY'),
            },
            {
              field: 'banco',
              headerName: 'Banco',
              flex: 1,
              sortable: false,
              valueGetter: ({ row }) =>
                findOnArray(row?.banco_id, drops?.EntidadeTipo, 'label'),
            },
            {
              field: 'agencia',
              headerName: 'Agência',
              flex: 1,
              sortable: false,
            },
            {
              field: 'conta',
              headerName: 'Conta',
              flex: 1,
              sortable: false,
            },
            {
              field: 'valor',
              headerName: 'Valor',
              type: 'number',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                value &&
                value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: ({ row, id }) => [
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() =>
                    setCheques((prev) =>
                      prev
                        ?.filter((f) => f?.id !== id)
                        ?.map((m, id) => ({ ...m, id }))
                    )
                  }
                />,
                <GridActionsCellItem
                  icon={<Icon>edit</Icon>}
                  label="Editar"
                  onClick={() => openModal(<Modal cheque={row} />, 90)}
                />,
              ],
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button variant="contained" color="primary" onClick={onAddLancamento}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ChequeModal;
