import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('cxlanctos_id') && (
        <Grid item xs={12}>
          <Input
            name="cxlanctos_id"
            control={control}
            label="Nº do Lançamento"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('dtlanc') && (
        <Grid item xs={6}>
          <InputMask
            name="dtlanc.i"
            control={control}
            label="Data de Lançamento Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtlanc') && (
        <Grid item xs={6}>
          <InputMask
            name="dtlanc.f"
            control={control}
            label="Data de Lançamento Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('conta_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="conta_id"
            control={control}
            label="Conta"
            options={drops?.Cxconta?.filter((f) => f?.visualizar === 'SIM')}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('historico') && (
        <Grid item xs={12}>
          <Input name="historico" control={control} label="Histórico" />
        </Grid>
      )}
      {checkDisplay('valor') && (
        <Grid item xs={12}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
      )}
      {checkDisplay('planoconta_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="planoconta_id"
            control={control}
            label="Plano de Conta"
            options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('ccusto_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="ccusto_id"
            control={control}
            label="Centro de Custo"
            options={drops?.Ccusto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('aplicacao_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="aplicacao_id"
            control={control}
            label="Aplicação"
            options={drops?.Aplicacao}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('frota_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="frota_id"
            control={control}
            label="Frota"
            options={drops?.Frota}
            multiple
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
