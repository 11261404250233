import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { VendaProvider } from 'contexts/VendaContext';
import { DocumentosProvider } from 'contexts/DocumentosContext';
import { PendênciasProvider } from 'contexts/PendênciasContext';
import { ChequesPendentesProvider } from 'contexts/ChequesPendentesContext';
import { ChequesBaixadosProvider } from 'contexts/ChequesBaixadosContext';
import { PagamentosPendentesProvider } from 'contexts/PagamentosPendentesContext';
import { PagamentosBaixadosProvider } from 'contexts/PagamentosBaixadosContext';
import { RecebimentosPendentesProvider } from 'contexts/RecebimentosPendentesContext';
import { RecebimentosBaixadosProvider } from 'contexts/RecebimentosBaixadosContext';
import { HaveresPendentesProvider } from 'contexts/HaveresPendentesContext';
import { HaveresBaixadosProvider } from 'contexts/HaveresBaixadosContext';
import { LançamentosProvider } from 'contexts/LançamentosContext';
import { LançamentosDetalhadosProvider } from 'contexts/LançamentosDetalhadosContext';
import { PrevisoesProvider } from 'contexts/PrevisoesContext';
import { DespesasProvider } from 'contexts/DespesasContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import { DashboardProvider } from 'contexts/DashboardContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <DashboardProvider>
            <VendaProvider>
              <DocumentosProvider>
                <PendênciasProvider>
                  <ChequesPendentesProvider>
                    <ChequesBaixadosProvider>
                      <PagamentosPendentesProvider>
                        <PagamentosBaixadosProvider>
                          <RecebimentosPendentesProvider>
                            <RecebimentosBaixadosProvider>
                              <HaveresPendentesProvider>
                                <HaveresBaixadosProvider>
                                  <LançamentosProvider>
                                    <LançamentosDetalhadosProvider>
                                      <PrevisoesProvider>
                                        <DespesasProvider>
                                          <RelatoriosProvider>
                                            <ModalProvider>
                                              <DialogProvider>
                                                <DrawerProvider>
                                                  {children}
                                                </DrawerProvider>
                                              </DialogProvider>
                                            </ModalProvider>
                                          </RelatoriosProvider>
                                        </DespesasProvider>
                                      </PrevisoesProvider>
                                    </LançamentosDetalhadosProvider>
                                  </LançamentosProvider>
                                </HaveresBaixadosProvider>
                              </HaveresPendentesProvider>
                            </RecebimentosBaixadosProvider>
                          </RecebimentosPendentesProvider>
                        </PagamentosBaixadosProvider>
                      </PagamentosPendentesProvider>
                    </ChequesBaixadosProvider>
                  </ChequesPendentesProvider>
                </PendênciasProvider>
              </DocumentosProvider>
            </VendaProvider>
          </DashboardProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
