import React, { useContext, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { dropIntervalo } from 'utils/drops';
import { findOnArray, summarizer } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import ItemModal from './Modals/ItemModal';
import PagamentoModal from './Modals/PagamentoModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const defaultValues = {
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    filial_id: user?.filial_id,
    cadastro_id: null,
    observacao: '',
    //ITENS
    descricao: '',
    quantidade: '',
    preco: '',
    planoconta_id: null,
    ccusto_id: null,
    aplicacao_id: null,
    frota_id: null,
    itens: [],
    //PAGAMENTO
    forma_pagto_id: null,
    parcela: '',
    data: null,
    intervalo: null,
    pagamento: [],
  };
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    handleSubmit(onRecalc)();
  }, [watch('itens')]);

  const onAddItem = (values) => {
    setValue('itens', [
      ...values?.itens,
      {
        subtotal: (values?.preco || 0) * (values?.quantidade || 0),
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        preco: values?.preco,
        planoconta_id: values?.planoconta_id,
        ccusto_id: values?.ccusto_id,
        aplicacao_id: values?.aplicacao_id,
        frota_id: values?.frota_id,
      },
    ]);
    resetField('descricao');
    resetField('quantidade');
    resetField('preco');
    resetField('planoconta_id');
    resetField('ccusto_id');
    resetField('aplicacao_id');
    resetField('frota_id');
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal = (values?.quantidade || 0) * (values?.preco || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onChangePagto = (values) => {
    const valor = summarizer(values?.itens, 'subtotal');
    if (valor <= 0) {
      return toastWarning('Documento sem valor');
    } else if (
      Boolean(values?.forma_pagto_id) &&
      Boolean(values?.parcela) &&
      Boolean(values?.data) &&
      Boolean(values?.intervalo)
    ) {
      let parcela = 1;
      const arr = [];
      while (parcela <= values?.parcela) {
        const dtvenc =
          values?.intervalo === 'DIARIO'
            ? moment(values?.data).add((parcela - 1) * 1, 'days')
            : values?.intervalo === 'SEMANAL'
            ? moment(values?.data).add((parcela - 1) * 7, 'days')
            : values?.intervalo === 'QUINZENAL'
            ? moment(values?.data).add((parcela - 1) * 15, 'days')
            : values?.intervalo === 'MENSAL'
            ? moment(values?.data).add((parcela - 1) * 1, 'months')
            : values?.intervalo === 'ANUAL'
            ? moment(values?.data).add((parcela - 1) * 1, 'years')
            : null;

        arr.push({
          forma_pagto_id: values?.forma_pagto_id,
          parcela,
          valor,
          dtvenc,
          fixo: true,
        });
        parcela++;
      }
      setValue('pagamento', arr);
    } else {
      toastWarning('Preencha todos os campos');
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      const pagamento = values?.pagamento.map((p, i) => {
        return { ...p, valor: valor_total };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: 1,
        natureza_operacao_id: 34,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: values?.dtemissao,
        observacao: values?.observacao,
      },
      Entrada: { dtentrada: moment().format('YYYY-MM-DD') },
      Itens: values?.itens?.map((i, index) => ({ sequencia: index + 1, ...i })),
      Financeiros: values?.pagamento,
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header titulo="Gerar Previsão" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask name="preco" control={control} label="Preço" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="planoconta_id"
                    control={control}
                    label="Plano de Conta"
                    options={drops?.Planoconta?.filter(
                      (p) => p?.operacional === 'SIM'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="ccusto_id"
                    control={control}
                    label="Centro de Custo"
                    options={drops?.Ccusto}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="aplicacao_id"
                    control={control}
                    label="Aplicação"
                    options={drops?.Aplicacao}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="frota_id"
                    control={control}
                    label="Frota"
                    options={drops?.Frota}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Preço Unitário</TableCell>
                          <TableCell>Sub Total</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>
                              {item?.preco?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.subtotal?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditItem({ values, index })
                                      }
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={drops?.FormaDePagamento?.filter(
                      (f) => f?.modulo !== 'VENDAS' && f?.modulo !== 'CHEQUES'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    name="parcela"
                    control={control}
                    label="Nº de Parcelas"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="data"
                    control={control}
                    label="Data Base"
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="intervalo"
                    control={control}
                    label="Intervalo"
                    options={dropIntervalo}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'label'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <PagamentoModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditPagto({ values, index })
                                      }
                                    />,
                                    'Editar Parcela'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeletePagto(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
