import React from 'react';
import { Grid } from '@mui/material';
import { dropOrigem } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('entidade') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="entidade"
            control={control}
            label="Entidade"
            options={drops?.Entidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('marketplace_name') && (
        <Grid item xs={12}>
          <Dropdown
            name="marketplace_name"
            control={control}
            label="Marketplace"
            options={drops?.IntegraAccess}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('origem') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="origem"
            control={control}
            label="Origem"
            options={dropOrigem}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('natureza') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="natureza"
            control={control}
            label="Natureza da Operação"
            options={drops?.NaturezaOperacao}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('valor') && (
        <Grid item xs={12}>
          <InputMask name="valor" control={control} label="Valor Total" />
        </Grid>
      )}
      {checkDisplay('valor_baixado') && (
        <Grid item xs={12}>
          <InputMask
            name="valor_baixado"
            control={control}
            label="Valor Baixado"
          />
        </Grid>
      )}
      {checkDisplay('valor_pendente') && (
        <Grid item xs={12}>
          <InputMask
            name="valor_pendente"
            control={control}
            label="Valor Pendente"
          />
        </Grid>
      )}
      {checkDisplay('filial') && (
        <Grid item xs={12}>
          <Dropdown
            name="filial"
            control={control}
            label="Filial"
            options={drops?.Filial}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input name="documento" control={control} label="Nº do Documento" />
        </Grid>
      )}
      {checkDisplay('observacao') && (
        <Grid item xs={12}>
          <Input name="observacao" control={control} label="Observação" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
