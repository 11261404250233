import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const Cartão = ({
  valor,
  contas,
  drops,
  onSubmit,
  isCheque,
  isPagamento,
  isRecebimento,
}) => {
  const defaultValues = {
    valor: valor || '',
    forma_pagto_id: null,
    autenticacao: '',
    cxconta_id: Boolean(contas?.seqcaixa) ? contas?.cxconta_id : null,
    historico: '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const drop = drops?.FormaDePagamento?.filter((f) => {
    if (isPagamento) {
      return (
        (f?.modalidade === '03-CARTAO DE CREDITO' ||
          f?.modalidade === '04-CARTAO DE DEBITO' ||
          f?.modalidade === '10-VALE ALIMENTAÇÃO' ||
          f?.modalidade === '11-VALE REFEIÇÃO' ||
          f?.modalidade === '12-VALE PRESENTE' ||
          f?.modalidade === '13-VALE COMBUSTIVEL') &&
        f?.modulo !== 'VENDAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isRecebimento) {
      return (
        (f?.modalidade === '03-CARTAO DE CREDITO' ||
          f?.modalidade === '04-CARTAO DE DEBITO' ||
          f?.modalidade === '10-VALE ALIMENTAÇÃO' ||
          f?.modalidade === '11-VALE REFEIÇÃO' ||
          f?.modalidade === '12-VALE PRESENTE' ||
          f?.modalidade === '13-VALE COMBUSTIVEL') &&
        f?.modulo !== 'COMPRAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isCheque) {
      return (
        (f?.modalidade === '03-CARTAO DE CREDITO' ||
          f?.modalidade === '04-CARTAO DE DEBITO' ||
          f?.modalidade === '10-VALE ALIMENTAÇÃO' ||
          f?.modalidade === '11-VALE REFEIÇÃO' ||
          f?.modalidade === '12-VALE PRESENTE' ||
          f?.modalidade === '13-VALE COMBUSTIVEL') &&
        f?.modulo === 'CHEQUES'
      );
    } else {
      return (
        f?.modalidade === '03-CARTAO DE CREDITO' ||
        f?.modalidade === '04-CARTAO DE DEBITO' ||
        f?.modalidade === '10-VALE ALIMENTAÇÃO' ||
        f?.modalidade === '11-VALE REFEIÇÃO' ||
        f?.modalidade === '12-VALE PRESENTE' ||
        f?.modalidade === '13-VALE COMBUSTIVEL'
      );
    }
  });

  const onAddLancamento = (values) => {
    if (!Boolean(values?.forma_pagto_id)) {
      return toastWarning('Selecione uma forma de pagamento');
    }
    if (Boolean(values?.valor <= 0)) {
      return toastWarning('Valor inválido');
    }
    if (!Boolean(values?.cxconta_id)) {
      return toastWarning('Selecione uma conta');
    }
    const data = {
      ...values,
      forma_pagto: findOnArray(values?.forma_pagto_id, drop, 'label'),
    };
    onSubmit(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drop}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          name="autenticacao"
          control={control}
          label="SKU (Autenticação)"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="cxconta_id"
          control={control}
          label="Conta"
          options={drops?.Cxconta}
          disabled={Boolean(contas?.seqcaixa)}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="historico"
          control={control}
          label="Observação"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onAddLancamento)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default Cartão;
