import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { getDatePagto, findOnArray, summarizer } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';
import moment from 'moment';

const Refinanciamento = ({
  valor,
  drops,
  onSubmit,
  isCheque,
  isPagamento,
  isRecebimento,
}) => {
  const defaultValues = {
    valor: valor || '',
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    observacao: '',
    pagamentos: [],
  };
  const { openModal, closeModal } = useModal();
  const { control, handleSubmit, watch, resetField, setValue } = useForm({
    defaultValues,
  });
  const drop = drops?.FormaDePagamento?.filter((f) => {
    if (isPagamento) {
      return (
        f?.indpag === '1-A PRAZO' &&
        f?.modulo !== 'VENDAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isRecebimento) {
      return (
        f?.indpag === '1-A PRAZO' &&
        f?.modulo !== 'COMPRAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isCheque) {
      return f?.indpag === '1-A PRAZO' && f?.modulo === 'CHEQUES';
    } else {
      return (
        f?.modalidade === '15-BOLETO BANCÁRIO' || f?.modalidade === '99-OUTROS'
      );
    }
  });

  const onAddPagto = (values) => {
    const forma_pagto_id = watch('forma_pagto_id');
    if (!Boolean(forma_pagto_id)) {
      return toastWarning('Selecione uma forma de pagamento');
    }
    const valor_total = watch('valor');
    if (values && valor_total > 0) {
      const pagamentos = [];
      let valor_lancado = 0;
      for (let parcela = 1; parcela <= values?.nvezes; parcela++) {
        const valor = parseFloat((valor_total / values?.nvezes).toFixed(2));
        pagamentos.push({
          id: parcela,
          parcela,
          dtvenc: getDatePagto({ ...values, parcela }),
          valor:
            parcela === values?.nvezes ? valor_total - valor_lancado : valor,
          forma_pagto_id,
          forma_pagto: findOnArray(forma_pagto_id, drop, 'label'),
        });
        valor_lancado += valor;
      }
      setValue('pagamentos', pagamentos);
    } else {
      setValue('pagamentos', []);
    }
  };

  const onAddLancamento = ({
    forma_pagto_id,
    condicao_pagamento_id,
    pagamentos,
    observacao,
  }) => {
    if (!Boolean(forma_pagto_id)) {
      return toastWarning('Selecione uma forma de pagamento');
    }
    if (!Boolean(condicao_pagamento_id)) {
      return toastWarning('Selecione uma condição de pagamento');
    }
    if (Boolean(pagamentos?.length)) {
      const boletos = pagamentos?.map((p) => ({
        parcela: p?.parcela,
        dtvenc: p?.dtvenc,
        valor: p?.valor,
        observacao,
      }));
      const data = {
        valor: summarizer(boletos, 'valor'),
        boletos,
        refinanciamento: true,
        forma_pagto_id,
        condicao_pagamento_id,
        forma_pagto: findOnArray(forma_pagto_id, drop, 'label'),
      };
      onSubmit(data);
    }
  };

  const Modal = ({ item }) => {
    const defaultValues = {
      valor: item?.valor || '',
      dtvenc: item?.dtvenc || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });

    const onSubmit = (values) => {
      if (Boolean(values?.valor <= 0)) {
        return toastWarning('Valor inválido');
      }
      const pagamentos = watch('pagamentos')?.map((p) => {
        if (item?.id === p?.id) {
          return { ...p, ...values };
        }
        return p;
      });
      setValue('pagamentos', pagamentos);
      closeModal();
    };

    return (
      <>
        <DialogTitle>Editar Boleto</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12} sm={6}>
              <InputMask name="valor" control={control} label="Valor" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                name="dtvenc"
                control={control}
                label="Data de Vencimento"
                type="date"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>Confirmar</Button>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drop}
          onValueChange={() => {
            setValue('pagamentos', []);
            resetField('condicao_pagamento_id');
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="condicao_pagamento_id"
          control={control}
          label="Condição de Pagamento"
          options={drops?.CondicaoPagamento}
          onValueChange={onAddPagto}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="observacao"
          control={control}
          label="Observação"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={watch('pagamentos')}
          columns={[
            {
              field: 'parcela',
              headerName: 'Parcela',
              flex: 2,
              sortable: false,
            },
            {
              field: 'forma_pagto',
              headerName: 'Forma de Pagamento',
              flex: 4,
              sortable: false,
            },
            {
              field: 'dtvenc',
              headerName: 'Data de Vencimento',
              flex: 2,
              sortable: false,
              valueGetter: ({ value }) =>
                value && moment(value).format('DD/MM/YYYY'),
            },
            {
              field: 'valor',
              headerName: 'Valor',
              type: 'number',
              flex: 2,
              sortable: false,
              valueGetter: ({ value }) =>
                value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Icon>edit</Icon>}
                  label="Editar"
                  onClick={() => openModal(<Modal item={row} />, 80)}
                />,
              ],
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onAddLancamento)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default Refinanciamento;
