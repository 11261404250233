import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { LançamentosDetalhadosContext } from 'contexts/LançamentosDetalhadosContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const LançamentosDetalhados = () => {
  const rotina = 'LançamentosDetalhados';
  const titulo = 'Lançamentos Detalhados';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { state } = useLocation();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls, deleteGrid } = useContext(GridContext);
  const { lançamentosDetalhados, getLançamentosDetalhados, getLoading } =
    useContext(LançamentosDetalhadosContext);
  const defaultValues = state?.id
    ? { ...lançamentosDetalhados?.filter, cxlanctos_id: state?.id?.toString() }
    : lançamentosDetalhados?.filter;
  const { control, getValues, reset } = useForm({ defaultValues });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getLançamentosDetalhados({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: lançamentosDetalhados?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: lançamentosDetalhados?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={lançamentosDetalhados?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];

  const rowOptions = [
    {
      name: 'Deletar',
      icon: 'delete',
      show: ({ row }) => row?.excluir === 'SIM',
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteGrid({
                params: { rotina, id: row?.id },
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={lançamentosDetalhados?.colunas}
      />
      <Card>
        <Grid
          grid={lançamentosDetalhados}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter
              grid={lançamentosDetalhados}
              control={control}
              drops={drops}
            />
          }
        />
      </Card>
    </Container>
  );
};

export default LançamentosDetalhados;
