import React, { useContext, useState } from 'react';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { useDialog } from 'components/Modals';
import CustomTabs from '../Tabs';

const LançamentoModal = ({
  valor,
  isRecebimento,
  isPagamento,
  isCheque,
  financeiros,
  onSubmit,
}) => {
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { contas } = useContext(VendaContext);
  const [tab, setTab] = useState(0);
  const cadastro_id = financeiros?.find((f) =>
    Boolean(f?.entidade_id)
  )?.entidade_id;
  const same =
    [...new Set(financeiros?.map((c) => c?.entidade_id))]?.length === 1;

  const onConfirm = (data) => {
    onSubmit(data);
    closeDialog();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" align="center">
          Total:{' '}
          {valor?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={tab} onChange={(_, v) => setTab(v)} variant="fullWidth">
          <Tab label={isCheque ? 'Depósito' : 'Caixa / Banco'} value={0} />
          {!isCheque && <Tab label="Cartão" value={1} />}
          {!isCheque && same && <Tab label="Cheque - Pré" value={2} />}
          {!isCheque && same && <Tab label="Compensação" value={3} />}
          {!isCheque && <Tab label="Refinanciamento" value={4} />}
          {!isCheque && same && <Tab label="Haver" value={5} />}
          {isCheque && <Tab label="Desconto" value={6} />}
          {isCheque && <Tab label="Substituição" value={7} />}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <CustomTabs
          tab={tab}
          drops={drops}
          valor={valor > 0 ? valor : 0}
          contas={contas}
          onSubmit={onConfirm}
          isCheque={isCheque}
          cadastro_id={cadastro_id}
          isPagamento={isPagamento}
          isRecebimento={isRecebimento}
        />
      </Grid>
    </Grid>
  );
};
export default LançamentoModal;
