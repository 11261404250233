import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { PendênciasContext } from 'contexts/PendênciasContext';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const DevoluçãoModal = ({ item, callback }) => {
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postBaixa, postLoading } = useContext(PendênciasContext);
  const forma_pagto_id =
    drops?.FormaDePagamento?.find(
      (f) =>
        f?.modulo === 'CHEQUES' &&
        f?.label?.includes('DEVOL') &&
        f?.modalidade === '90-SEM PAGAMENTO'
    )?.value || null;
  const defaultValues = {
    valor: item?.valor || '',
    forma_pagto_id,
    alinea_id: null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const onSubmit = (values) => {
    const data = {
      dtlanc: '',
      financeiros: [{ ...item, id: item?.financeiro_id }],
      lancamentos: [
        { valor: values?.valor, forma_pagto_id: values?.forma_pagto_id },
      ],
      operacao: 'CHEQUES',
      alinea_id: values?.alinea_id,
    };
    postBaixa({
      data,
      cb: () => {
        if (callback) {
          callback();
        }
        closeDialog();
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" align="center">
          Total:{' '}
          {item?.valor?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="valor" control={control} label="Valor" disabled />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drops?.FormaDePagamento}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="alinea_id"
          control={control}
          label="Alinea"
          options={drops?.AlineaCheque}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default DevoluçãoModal;
