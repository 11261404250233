import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { PendênciasContext } from 'contexts/PendênciasContext';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const AlteraçãoModal = ({ financeiros = [], callback, modulo }) => {
  const defaultValues = {
    forma_pagto_id: null,
    dtvenc: null,
    observacao: '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postPendencias, postLoading } = useContext(PendênciasContext);

  const onSubmit = (values) => {
    const data = financeiros?.map((f) => ({
      id: f?.id,
      forma_pagto_id: values?.forma_pagto_id,
      dtvenc: values?.dtvenc,
      observacao: values?.observacao,
    }));
    postPendencias({
      data,
      cb: () => {
        if (callback) {
          callback();
        }
        closeDialog();
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drops?.FormaDePagamento?.filter(
            (f) => f?.modulo === modulo || f?.modulo === 'GERAL'
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="dtvenc"
          control={control}
          label="Data de Vencimento"
          type="date"
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="observacao"
          control={control}
          label="Observação"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default AlteraçãoModal;
