import React, { useContext, useEffect } from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { findOnArray } from 'utils/functions';
import { dropBoolean } from 'utils/drops';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { LançamentosContext } from 'contexts/LançamentosContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Card from 'components/Card';
import moment from 'moment';
import styles from './styles';
import ItemModal from './Modals/ItemModal';

const Gerar = () => {
  const defaultValues = {
    dtemis: moment().format('YYYY-MM-DD'),
    dtlanc: moment().format('YYYY-MM-DD'),
    dtlanc_org: moment().format('YYYY-MM-DD'),
    conta_id: null,
    conciliado: null,
    conciliar: 'SIM',
    documentocx: '',
    historico: '',
    valor: '',
    planoconta_id: null,
    ccusto_id: null,
    aplicacao_id: null,
    frota_id: null,
    cxlancto_det: [],
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { getLoading, getLançamento, postLoading, postLançamento } =
    useContext(LançamentosContext);
  const { deleteGrid, deleteLoading } = useContext(GridContext);
  const { control, handleSubmit, setValue, watch, resetField } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (state?.id) {
      getLançamento({ id: state?.id, cb: onRender });
    } else if (state?.conta_id) {
      setValue('conta_id', state?.conta_id);
    }
  }, []);

  const onRender = (values) => {
    setValue('dtemis', values?.dtemis || null);
    setValue('dtlanc', values?.dtlanc || null);
    setValue('dtlanc_org', values?.dtlanc_org || null);
    setValue('conta_id', values?.conta_id || null);
    setValue('conciliado', values?.conciliado || null);
    setValue('documentocx', values?.documentocx || '');
    setValue('conciliar', values?.conciliar || 'SIM');
    setValue(
      'cxlancto_det',
      values?.CxlanctosDets?.map((d) => ({
        id: d?.id,
        historico: d?.historico,
        valor: d?.valor,
        cxlanctos_id: d?.cxlanctos_id,
        planoconta: findOnArray(d?.planoconta_id, drops?.Planoconta, 'label'),
        planoconta_id: d?.planoconta_id,
        ccusto: findOnArray(d?.ccusto_id, drops?.Ccusto, 'label'),
        ccusto_id: d?.ccusto_id,
        aplicacao: findOnArray(d?.aplicacao_id, drops?.Aplicacao, 'label'),
        aplicacao_id: d?.aplicacao_id,
        frota: findOnArray(d?.frota_id, drops?.Frota, 'label'),
        frota_id: d?.frota_id,
      }))
    );
  };

  const onSubmit = (values) => {
    const data = {
      cxlancto: {
        id: state?.id,
        dtemis: values?.dtemis,
        dtlanc: values?.dtlanc,
        dtlanc_org: values?.dtlanc_org,
        conta_id: values?.conta_id,
        conciliado: values?.conciliado,
        documentocx: values?.documentocx,
      },
      cxlancto_det: values?.cxlancto_det,
    };
    postLançamento({
      data,
      cb: () => navigate(-1),
    });
  };

  const onAddDet = (values) => {
    if (!Boolean(values?.historico)) {
      return toastWarning('Histórico não informado');
    } else if (!Boolean(values?.valor)) {
      return toastWarning('Valor não informado');
    } else if (!Boolean(values?.planoconta_id)) {
      return toastWarning('Plano de Conta não informado');
    }
    const det = {
      historico: values?.historico,
      valor: values?.valor,
      planoconta: findOnArray(
        values?.planoconta_id,
        drops?.Planoconta,
        'label'
      ),
      planoconta_id: values?.planoconta_id,
      ccusto: findOnArray(values?.ccusto_id, drops?.Ccusto, 'label'),
      ccusto_id: values?.ccusto_id,
      aplicacao: findOnArray(values?.aplicacao_id, drops?.Aplicacao, 'label'),
      aplicacao_id: values?.aplicacao_id,
      frota: findOnArray(values?.frota_id, drops?.Frota, 'label'),
      frota_id: values?.frota_id,
    };
    if (values?.index >= 0) {
      let newDet = watch('cxlancto_det');
      let old = newDet[values.index] || {};
      newDet[values.index] = { ...old, ...det };
      setValue('cxlancto_det', newDet);
      closeDialog();
    } else {
      setValue('cxlancto_det', [...values?.cxlancto_det, det]);
      resetField('historico');
      resetField('valor');
      resetField('planoconta_id');
      resetField('ccusto_id');
      resetField('aplicacao_id');
      resetField('frota_id');
    }
  };

  const onDeleteDet = ({ index, id }) => {
    if (id) {
      deleteGrid({
        params: { rotina: 'LançamentosDetalhados', id },
        cb: () =>
          setValue(
            'cxlancto_det',
            watch('cxlancto_det')?.filter((_, i) => i !== index)
          ),
      });
    } else {
      setValue(
        'cxlancto_det',
        watch('cxlancto_det')?.filter((_, i) => i !== index)
      );
    }
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header
        titulo={(Boolean(state?.id) ? 'Editar' : 'Gerar') + ' Lançamento'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card title="Informações do Lançamento">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputMask
                  type="date"
                  name="dtemis"
                  control={control}
                  label="Data de Emissão"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  type="date"
                  name="dtlanc"
                  control={control}
                  label="Data do Lançamento"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  name="conta_id"
                  control={control}
                  label="Conta"
                  options={drops?.Cxconta?.filter((f) => f?.inserir === 'SIM')}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input name="documentocx" control={control} label="Documento" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Dropdown
                  name="conciliado"
                  control={control}
                  label="Conciliado"
                  options={dropBoolean}
                  disabled={watch('conciliar') === 'NAO'}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex">
          <Card title="Detalhes" style={styles?.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input name="historico" control={control} label="Histórico" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask name="valor" control={control} label="Valor" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  name="planoconta_id"
                  control={control}
                  label="Plano de Conta"
                  options={drops?.Planoconta?.filter(
                    (p) => p?.operacional === 'SIM'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="ccusto_id"
                  control={control}
                  label="Centro de Custo"
                  options={drops?.Ccusto}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="aplicacao_id"
                  control={control}
                  label="Aplicação"
                  options={drops?.Aplicacao}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="frota_id"
                  control={control}
                  label="Frota"
                  options={drops?.Frota}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onAddDet)}
                >
                  ADICIONAR
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex">
          <Card style={styles?.card}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Histórico</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Plano de Conta</TableCell>
                    <TableCell>Centro de Custo</TableCell>
                    <TableCell>Aplicação</TableCell>
                    <TableCell>Frota</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch('cxlancto_det')?.map((item, index) => (
                    <TableRow key={index?.toString()}>
                      <TableCell>{item?.historico}</TableCell>
                      <TableCell>
                        {item?.valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>{item?.planoconta}</TableCell>
                      <TableCell>{item?.ccusto}</TableCell>
                      <TableCell>{item?.aplicacao}</TableCell>
                      <TableCell>{item?.frota}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => onDeleteDet({ index, id: item?.id })}
                        >
                          <Delete />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() =>
                            openDialog(
                              <ItemModal
                                item={{ ...item, index }}
                                onSubmit={onAddDet}
                              />,
                              'Editar Detalhe'
                            )
                          }
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading || deleteLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Gerar;
