import React, { useContext } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Icon,
} from '@mui/material';
import Button from 'components/Button';
import { useModal } from 'components/Modals';
import { LançamentosContext } from 'contexts/LançamentosContext';

const DeleteModal = ({ item, callback }) => {
  const { closeModal } = useModal();
  const { deleteLançamento, deleteLoading } = useContext(LançamentosContext);

  const title = Boolean(item?.financeiro_movimento_id)
    ? 'Confirmar Estorno do Lançamento'
    : 'Confirmar Exclusão';
  const icon = Boolean(item?.financeiro_movimento_id)
    ? 'undo'
    : 'delete_forever';
  const text = Boolean(item?.financeiro_movimento_id)
    ? 'Atenção! Ao estornar o lançamento, a(s) baixa(s) do financeiro serão reabertas. Deseja realmente estornar o lançamento?'
    : 'Deseja realmente excluir o lançamento?';

  const onSubmit = () =>
    deleteLançamento({
      params: { cxlanctos_id: item?.id },
      cb: () => {
        closeModal();
        if (callback) {
          callback();
        }
      },
    });

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText>
          <Icon sx={{ fontSize: 150 }}>{icon}</Icon>
        </DialogContentText>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} loading={deleteLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteModal;
