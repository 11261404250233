import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  entidade: [],
  dtvenc: {
    i: null,
    f: null,
  },
  dtlanc: {
    i: null,
    f: null,
  },
  documento: '',
  parcela: '',
  valor: '',
  valor_outros: '',
  forma_pagto: [],
  filial: [],
  dtemissao: {
    i: null,
    f: null,
  },
  dtentrada: {
    i: null,
    f: null,
  },
  observacao: '',
};

export const PagamentosBaixadosContext = createContext();

export const PagamentosBaixadosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [pagamentosBaixados, setPagamentosBaixados] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getPagamentosBaixados = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: pagamentosBaixados?.filter,
        page: pagamentosBaixados?.page,
        size: pagamentosBaixados?.size,
        order: pagamentosBaixados?.order,
        ...payload,
      });
      setPagamentosBaixados((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <PagamentosBaixadosContext.Provider
      value={{
        getLoading,
        getPagamentosBaixados,
        pagamentosBaixados,
      }}
    >
      {children}
    </PagamentosBaixadosContext.Provider>
  );
};
