import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  cxlanctos_id: '',
  dtlanc: {
    i: null,
    f: null,
  },
  conta_id: [],
  historico: '',
  valor: '',
  planoconta_id: [],
  ccusto_id: [],
  aplicacao_id: [],
  frota_id: [],
};

export const LançamentosDetalhadosContext = createContext();

export const LançamentosDetalhadosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [lançamentosDetalhados, setLançamentosDetalhados] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtlanc', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const getLançamentosDetalhados = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: lançamentosDetalhados?.filter,
        page: lançamentosDetalhados?.page,
        size: lançamentosDetalhados?.size,
        order: lançamentosDetalhados?.order,
        ...payload,
      });
      setLançamentosDetalhados((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <LançamentosDetalhadosContext.Provider
      value={{
        getLoading,
        getLançamentosDetalhados,
        lançamentosDetalhados,
      }}
    >
      {children}
    </LançamentosDetalhadosContext.Provider>
  );
};
