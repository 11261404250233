import { useContext, useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { DashboardContext } from 'contexts/DashboardContext';
import { AuthContext } from 'contexts/AuthContext';
import { defaultTheme } from 'utils/theme';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Card from 'components/Card';
import Pizza from './Charts/Pizza';
import Bar from './Charts/Bar';

const App = () => {
  const [tabInad, setTabInad] = useState(0);
  const [tabInadVend, setTabInadVend] = useState(0);
  const { user } = useContext(AuthContext);
  const { dashData, getLoading, getDashboard } = useContext(DashboardContext);
  const { primary, secondary, grey } = defaultTheme?.palette;

  const mockData = [
    { id: 0, color: primary?.dark },
    { id: 1, color: primary?.light },
    { id: 2, color: primary?.main },
    { id: 3, color: grey[1000] },
    { id: 4, color: secondary?.dark },
    { id: 5, color: secondary?.light },
    { id: 6, color: secondary?.main },
  ];

  const renderGraph = (type, value) =>
    value
      ? (dashData?.[type] || [])?.map((d, i) => ({
          ...d,
          ...mockData[i],
          value: d[value],
        }))
      : (dashData?.[type] || [])?.map((d, i) => ({ ...d, ...mockData[i] }));

  useEffect(() => {
    getDashboard();
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Grid container spacing={5}>
        {!user?.permissoes?.block_dashboard_inadimplencias && (
          <Grid item xs={12} sm={6} display="flex">
            <Card title="Inadimplências" style={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    variant="fullWidth"
                    value={tabInad}
                    onChange={(_, v) => setTabInad(v)}
                  >
                    <Tab value={0} label="Quantidade" />
                    <Tab value={1} label="Valor" />
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {tabInad === 0 && (
                    <Pizza
                      title="Inadimplências"
                      data={renderGraph('inadimplencias', 'quantidade')}
                    />
                  )}
                  {tabInad === 1 && (
                    <Pizza
                      title="Inadimplências"
                      data={renderGraph('inadimplencias', 'valor')}
                    />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
        {!user?.permissoes?.block_dashboard_inadimplencias_vendedor && (
          <Grid item xs={12} sm={6} display="flex">
            <Card title="Inadimplências por Vendedor" style={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    variant="fullWidth"
                    value={tabInadVend}
                    onChange={(_, v) => setTabInadVend(v)}
                  >
                    <Tab value={0} label="Quantidade" />
                    <Tab value={1} label="Valor" />
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {tabInadVend === 0 && (
                    <Bar
                      data={dashData?.inadimplencias_vendedor}
                      dataKey="qt"
                    />
                  )}
                  {tabInadVend === 1 && (
                    <Bar
                      data={dashData?.inadimplencias_vendedor}
                      dataKey="vl"
                    />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default App;
