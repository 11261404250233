import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import { LançamentosContext } from 'contexts/LançamentosContext';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import moment from 'moment';

const ConciliaçãoModal = ({ item, callback }) => {
  const defaultValues = { dtlanc: moment().format('YYYY-MM-DD') };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { closeModal } = useModal();
  const { postLançamento, postLoading } = useContext(LançamentosContext);

  const onSubmit = (values) => {
    postLançamento({
      data: {
        cxlancto: {
          id: item?.id,
          ...values,
          conciliado: 'SIM',
        },
      },
      cb: () => {
        closeModal();
        if (callback) {
          callback();
        }
      },
    });
  };

  return (
    <>
      <DialogTitle>Conciliar Lançamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <InputMask
              type="date"
              name="dtlanc"
              control={control}
              label="Data de Lançamento"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ConciliaçãoModal;
