import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { PrevisoesContext } from 'contexts/PrevisoesContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Previsões = () => {
  const rotina = 'Previsões';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls, deleteGrid } = useContext(GridContext);
  const { previsoes, getPrevisoes, getLoading } = useContext(PrevisoesContext);
  const { control, getValues, reset } = useForm({
    defaultValues: previsoes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getPrevisoes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () => navigate('/app/Previsoes/Gerar'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: previsoes?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: previsoes?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={previsoes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: 'Gerar Despesa',
      icon: 'post_add',
      menu: true,
      action: ({ row }) => navigate('/app/Despesas/Gerar', { state: row }),
    },
    {
      name: 'Deletar',
      icon: 'delete',
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteGrid({
                params: { rotina, id: row?.id },
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={previsoes?.colunas} />
      <Card>
        <Grid
          grid={previsoes}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={previsoes} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Previsões;
