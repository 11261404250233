import { useContext, useState } from 'react';
import {
  Box,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  InputAdornment,
  LinearProgress,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { decryptURL, summarizer, filterSearch } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { PendênciasContext } from 'contexts/PendênciasContext';
import { LançamentosContext } from 'contexts/LançamentosContext';
import { useModal, useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Header from 'components/Header';
import Dropdown from 'components/Dropdown';
import Container from 'components/Container';
import localeText from 'utils/localeText';
import moment from 'moment';

const Conciliar = () => {
  const { hash } = useParams();
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postBaixa } = useContext(PendênciasContext);
  const { getLoading, loadOfx, postLançamentos } =
    useContext(LançamentosContext);
  const [like, setLike] = useState('');
  const [ofx, setOfx] = useState([]);
  const [lancamentos, setLancamentos] = useState([]);
  const [pagamentos, setPagamentos] = useState([]);
  const [selected, setSelected] = useState([]);
  const decrypt = decryptURL(hash);

  const onFileChange = ({ target }) => {
    const file = target?.files[0] || null;
    const formData = new FormData();
    formData.append('conta_id', decrypt?.id);
    formData.append('midia', file);
    loadOfx({
      data: formData,
      cb: (res) => {
        if (Boolean(res?.lancamentos?.length)) {
          setLancamentos(res?.lancamentos || []);
        }
        if (Boolean(res?.pagamentos?.length)) {
          setPagamentos(res?.pagamentos || []);
        }
        if (Boolean(res?.ofx?.length)) {
          setOfx(res?.ofx || []);
        }
      },
    });
    target.value = '';
  };

  const onSubmitLancamento = (values) => {
    const documentos = selected?.map((s) => s?.documento);
    const data = selected?.map((s) => ({
      dtemis: s?.data,
      dtlanc: s?.data,
      dtlanc_org: s?.data,
      conta_id: decrypt?.id,
      conciliado: 'SIM',
      documentocx: s?.documento,
      dets: [
        {
          historico: s?.descricao,
          valor: s?.valor,
          planoconta_id: values?.planoconta_id,
        },
      ],
    }));
    postLançamentos({ data, cb: () => callback(documentos) });
  };

  const onSubmitBaixa = (values) => {
    const lancto = values?.item || null;
    const fins = values?.selected || [];
    const valor = Math.abs(summarizer(fins, 'valor') || 0);
    const forma_pagto_id = fins?.find((f) =>
      Boolean(f?.forma_pagto_id)
    )?.forma_pagto_id;
    const data = {
      dtlanc: lancto?.data,
      operacao: 'PAGAMENTOS',
      lancamentos: [
        {
          cxconta_id: decrypt?.id,
          dtlanc: lancto?.data,
          documentocx: lancto?.documento,
          conciliado: 'SIM',
          valor: Math.abs(lancto?.valor || 0),
          forma_pagto_id,
        },
      ],
      financeiros: fins?.map((item) => ({
        documento: item?.documento,
        entidade_id: item?.entidade_id,
        forma_pagto_id: item?.forma_pagto_id,
        id: item?.id,
        parcela: item?.parcela,
        valor: item?.valor,
      })),
      outros: Math.abs(lancto?.valor || 0) - valor,
    };
    postBaixa({ data, cb: () => callback([lancto?.documento]) });
  };

  const onSubmitConciliacao = (values) => {
    const lancto = values?.item || null;
    const dets = values?.selected || [];
    if (lancto?.valor !== summarizer(dets, 'valor')) {
      return toastWarning(
        'Valor do Lançamento não confere com a soma dos valores selecionados'
      );
    }
    const data = dets?.map((d) => ({
      id: d?.id,
      conciliado: 'SIM',
      dtlanc: lancto?.data,
      conta_id: decrypt?.id,
      documentocx: lancto?.documento,
    }));
    postLançamentos({ data, cb: () => callback([lancto?.documento]) });
  };

  const callback = (documentos = []) => {
    const formData = new FormData();
    formData.append('conta_id', decrypt?.id);
    loadOfx({
      data: formData,
      cb: (res) => {
        if (Boolean(res?.lancamentos?.length)) {
          setLancamentos(res?.lancamentos || []);
        }
        if (Boolean(res?.pagamentos?.length)) {
          setPagamentos(res?.pagamentos || []);
        }
        if (Boolean(res?.ofx?.length)) {
          setOfx(res?.ofx || []);
        }
      },
    });
    const newOfx = ofx?.filter((f) => !documentos?.includes(f?.documento));
    setOfx(newOfx);
    setSelected([]);
    closeDialog();
    closeModal();
  };

  const onOpenLancamento = () => {
    if (Boolean(selected?.length)) {
      openModal(<LancamentoModal selected={selected} />);
    } else {
      toastWarning('Selecione ao menos um item');
    }
  };

  const onOpenBaixa = (item) => {
    openDialog(<BaixaModal item={item} />, 'Baixar');
  };

  const onOpenConciliacao = (item) => {
    openDialog(<ConciliarModal item={item} />, 'Conciliar');
  };

  const LancamentoModal = () => {
    const defaultValues = {
      planoconta_id: null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(LançamentosContext);
    return (
      <>
        <DialogTitle>Lançar</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Dropdown
                name="planoconta_id"
                control={control}
                label="Plano de Conta"
                options={drops?.Planoconta?.filter(
                  (p) => p?.operacional === 'SIM'
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            loading={postLoading}
            onClick={handleSubmit(onSubmitLancamento)}
          >
            Confirmar
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Voltar
          </Button>
        </DialogActions>
      </>
    );
  };

  const BaixaModal = ({ item }) => {
    const [like, setLike] = useState('');
    const [selected, setSelected] = useState([]);
    const { postLoading } = useContext(PendênciasContext);
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHeader item={item} />
        </Grid>
        <Grid item xs={12}>
          <Input
            placeholder="Buscar..."
            value={like}
            onChange={(e) => setLike(e?.target?.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            {...gridProps}
            rows={filterSearch(pagamentos, like)}
            columns={[
              {
                field: 'entidade',
                headerName: 'Entidade',
                flex: 1,
              },
              {
                field: 'valor',
                headerName: 'Valor',
                width: 200,
                valueFormatter: ({ value }) =>
                  (value || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'dtvenc',
                headerName: 'Data de Vencimento',
                width: 200,
                valueFormatter: ({ value }) =>
                  value && moment(value).format('DD/MM/YYYY'),
              },
              {
                field: 'documento',
                headerName: 'Nº do Documento',
                width: 200,
              },
              {
                field: 'forma_pagto',
                headerName: 'Forma de Pagamento',
                width: 300,
              },
            ]}
            selectionModel={selected?.map((s) => s?.id)}
            onSelectionModelChange={(ids) =>
              setSelected(
                ids?.map((id) => pagamentos?.find((f) => f?.id === id))
              )
            }
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="contained"
            loading={postLoading}
            onClick={() => onSubmitBaixa({ item, selected })}
          >
            SALVAR
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ConciliarModal = ({ item }) => {
    const [like, setLike] = useState('');
    const [selected, setSelected] = useState([]);
    const { postLoading } = useContext(LançamentosContext);
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHeader item={item} />
        </Grid>
        <Grid item xs={12}>
          <Input
            placeholder="Buscar..."
            value={like}
            onChange={(e) => setLike(e?.target?.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            {...gridProps}
            rows={filterSearch(lancamentos, like)}
            columns={[
              {
                field: 'historico',
                headerName: 'Detalhes do Lançamento',
                flex: 1,
              },
              {
                field: 'valor',
                headerName: 'Valor',
                width: 200,
                valueFormatter: ({ value }) =>
                  (value || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'dtlanc',
                headerName: 'Data de Lançamento',
                width: 200,
                valueFormatter: ({ value }) =>
                  value && moment(value).format('DD/MM/YYYY'),
              },
              {
                field: 'planoconta',
                headerName: 'Plano de Contas',
                width: 400,
              },
            ]}
            selectionModel={selected?.map((s) => s?.id)}
            onSelectionModelChange={(ids) =>
              setSelected(
                ids?.map((id) => lancamentos?.find((f) => f?.id === id))
              )
            }
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            loading={postLoading}
            onClick={() => onSubmitConciliacao({ item, selected })}
          >
            SALVAR
          </Button>
        </Grid>
      </Grid>
    );
  };

  const CardHeader = ({ item }) => {
    const options = [
      {
        label: 'Valor',
        value: (item?.valor || 0)?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
      {
        label: 'Data',
        value:
          moment(item?.data).isValid() &&
          moment(item?.data).format('DD/MM/YYYY'),
      },
      { label: 'Documento', value: item?.documento },
    ];
    return (
      <Card title={item?.descricao}>
        {options?.map(({ value, label }, i) => (
          <Box key={i?.toString()} display="flex" alignItems="center">
            <Typography flex={1} variant="body1" fontWeight="bold">
              {label}:
            </Typography>
            <Typography flex={5} variant="body1">
              {value || '-'}
            </Typography>
          </Box>
        ))}
      </Card>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  const gridProps = {
    localeText,
    pageSize: 10,
    autoHeight: true,
    pagination: true,
    density: 'compact',
    loading: getLoading,
    checkboxSelection: true,
    disableColumnMenu: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
    disableSelectionOnClick: true,
    keepNonExistentRowsSelected: true,
    components: {
      LoadingOverlay: LinearProgress,
      Pagination: CustomPagination,
    },
  };

  return (
    <Container>
      <Header titulo="Conciliar Lançamentos" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <ButtonGroup variant="outlined" color="primary">
              <Button
                onClick={() => document.getElementById('upload-midia')?.click()}
              >
                IMPORTAR
              </Button>
              <Button onClick={onOpenLancamento}>LANÇAR</Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder="Buscar..."
              value={like}
              onChange={(e) => setLike(e?.target?.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              {...gridProps}
              rows={filterSearch(ofx, like)}
              columns={[
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  width: 100,
                  getActions: ({ row }) => {
                    const actions = [
                      <GridActionsCellItem
                        icon={
                          <Tooltip title="Conciliar">
                            <Icon>join_full</Icon>
                          </Tooltip>
                        }
                        label="Conciliar"
                        onClick={() => onOpenConciliacao(row)}
                      />,
                    ];
                    if (row?.valor < 0) {
                      actions?.unshift(
                        <GridActionsCellItem
                          icon={
                            <Tooltip title="Baixar">
                              <Icon>price_check</Icon>
                            </Tooltip>
                          }
                          label="Baixar"
                          onClick={() => onOpenBaixa(row)}
                        />
                      );
                    }
                    return actions;
                  },
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 1,
                },
                {
                  field: 'valor',
                  headerName: 'Valor',
                  width: 200,
                  valueFormatter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
                {
                  field: 'data',
                  headerName: 'Data',
                  width: 200,
                  valueFormatter: ({ value }) =>
                    value && moment(value).format('DD/MM/YYYY'),
                },
                {
                  field: 'documento',
                  headerName: 'Documento',
                  width: 200,
                },
              ]}
              getRowId={(row) => row?.index}
              selectionModel={selected?.map((s) => s?.index)}
              onSelectionModelChange={(ids) =>
                setSelected(ids?.map((id) => ofx?.find((f) => f?.index === id)))
              }
            />
          </Grid>
        </Grid>
      </Card>
      <input id="upload-midia" type="file" hidden onChange={onFileChange} />
    </Container>
  );
};

export default Conciliar;
