import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  dtvenc: {
    i: null,
    f: null,
  },
  descricao: '',
  parcela: '',
  valor: '',
  forma_pagto: [],
  entidade: [],
  filial: [],
};

export const PrevisoesContext = createContext();

export const PrevisoesProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [prevs, setPrevs] = useState([]);
  const [previsoes, setPrevisoes] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtvenc', sort: 'asc' },
    filter,
    page: 0,
    size: 10,
  });

  const getPrevisoes = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: previsoes?.filter,
        page: previsoes?.page,
        size: previsoes?.size,
        order: previsoes?.order,
        ...payload,
      });
      setPrevisoes((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getPrevs = async (params) => {
    try {
      setPrevs(null);
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Compra/Previsoes', {
        params,
      });
      setPrevs(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postDespesa = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Financeiro/Previsoes', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <PrevisoesContext.Provider
      value={{
        getLoading,
        getPrevisoes,
        previsoes,
        getPrevs,
        prevs,
        postDespesa,
        postLoading,
      }}
    >
      {children}
    </PrevisoesContext.Provider>
  );
};
