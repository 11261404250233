import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  entidade: [],
  marketplace_name: [],
  origem: [],
  natureza: [],
  dtemissao: {
    i: null,
    f: null,
  },
  dtlanc: {
    i: null,
    f: null,
  },
  valor: '',
  filial: [],
  documento: '',
  observacao: '',
};

export const HaveresBaixadosContext = createContext();

export const HaveresBaixadosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [haveresBaixados, setHaveresBaixados] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getHaveresBaixados = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: haveresBaixados?.filter,
        page: haveresBaixados?.page,
        size: haveresBaixados?.size,
        order: haveresBaixados?.order,
        ...payload,
      });
      setHaveresBaixados((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <HaveresBaixadosContext.Provider
      value={{
        getLoading,
        getHaveresBaixados,
        haveresBaixados,
      }}
    >
      {children}
    </HaveresBaixadosContext.Provider>
  );
};
