import React, { useEffect } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { findOnArray, summarizer, filterSearch } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Input from 'components/Input';
import api from 'services/api';
import moment from 'moment';

const ChequeLista = ({
  drops,
  onSubmit,
  isCheque,
  isPagamento,
  isRecebimento,
}) => {
  const defaultValues = {
    search: '',
    forma_pagto_id: null,
    itens: [],
    selected: [],
    loading: false,
  };
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });
  const drop = drops?.FormaDePagamento?.filter((f) => {
    if (isPagamento) {
      return (
        f?.modalidade === '02-CHEQUE' &&
        f?.modulo !== 'VENDAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isRecebimento) {
      return (
        f?.modalidade === '02-CHEQUE' &&
        f?.modulo !== 'COMPRAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isCheque) {
      return f?.modalidade === '02-CHEQUE' && f?.modulo === 'CHEQUES';
    } else {
      return f?.modalidade === '02-CHEQUE';
    }
  });

  const loadItens = async () => {
    try {
      setValue('loading', true);
      setValue('selected', []);
      const { data } = await api.get(`/Financeiro/Pendencias/Compensacao`);
      setValue(
        'itens',
        data?.cheques?.filter((f) => f?.grupo === 'CHEQUES')
      );
    } catch (error) {
    } finally {
      setValue('loading', false);
    }
  };

  const onAddLancamento = ({ forma_pagto_id, selected, itens }) => {
    if (!Boolean(forma_pagto_id)) {
      return toastWarning('Selecione uma forma de pagamento');
    }
    if (Boolean(selected?.length)) {
      const cheques = selected?.map((s) => {
        const cheque = itens?.find((f) => f?.id === s);
        return {
          financeiro_id: cheque?.id,
          valor: cheque?.valor_pendente,
        };
      });
      const data = {
        valor: summarizer(cheques, 'valor'),
        cheques,
        forma_pagto_id,
        forma_pagto: findOnArray(forma_pagto_id, drop, 'label'),
      };
      onSubmit(data);
    }
  };

  useEffect(() => {
    loadItens();
  }, []);

  if (watch('loading')) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Input
          name="search"
          label="Buscar..."
          control={control}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drop}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={filterSearch(
            watch('itens'),
            watch('search')?.replace(/[,.<:;]/g, '')
          )}
          columns={[
            {
              field: 'nome',
              headerName: 'Nome',
              flex: 2,
              sortable: false,
            },
            {
              field: 'cheque',
              headerName: 'Nº do Cheque',
              type: 'number',
              flex: 1,
              sortable: false,
            },
            {
              field: 'banda',
              headerName: 'Banda',
              type: 'number',
              flex: 1,
              sortable: false,
            },
            {
              field: 'valor_pendente',
              headerName: 'Valor',
              type: 'number',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                value &&
                value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
            {
              field: 'dtvenc',
              headerName: 'Data de Vencimento',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                value && moment(value).format('DD/MM/YYYY'),
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          checkboxSelection
          keepNonExistentRowsSelected
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          selectionModel={watch('selected')}
          onSelectionModelChange={(ids) => setValue('selected', ids)}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onAddLancamento)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChequeLista;
