import moment from 'moment';
import { renderLogo } from 'utils/functions';

const mock = [
  {
    field: 'dtemissao',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'dtlanc',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'valor',
    valueGetter: ({ value }) =>
      value?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
  {
    field: 'marketplace_name',
    renderCell: ({ value }) => renderLogo(value),
  },
];

export default mock;
