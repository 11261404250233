import React, { useContext, useState } from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Tooltip,
} from '@mui/material';
import { Delete, Edit, Print, RequestQuote } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { summarizer } from 'utils/functions';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import ButtonMenu from 'components/ButtonMenu';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from '../styles';
import moment from 'moment';

const Financeiros = ({
  drops,
  onSubmit,
  onDelete,
  documento = {},
  disableActions,
  onSubmitDespesa,
  onSubmitDuplicata,
}) => {
  const [tab, setTab] = useState(0);
  const { openDialog } = useDialog();
  const rotina = 'DocumentoFinanceiro';

  const Modal = ({ item }) => {
    const resto =
      (documento?.Totais?.documento || 0) -
      (summarizer(documento?.DocumentoFinanceiros || [], 'valor') || 0);
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      parcela: item?.parcela || documento?.DocumentoFinanceiros?.length + 1,
      dtvenc: item?.dtvenc || null,
      valor: item?.valor || (resto > 0 && resto) || '',
      forma_pagto_id: item?.forma_pagto_id || null,
      observacao: item?.observacao || '',
      fixo: true,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    const onConfirm = (data) => onSubmit({ Financeiros: [data] });

    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputMask
            name="dtvenc"
            control={control}
            label="Data de Vencimento"
            type="date"
          />
        </Grid>
        <Grid item xs={4}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
        <Grid item xs={4}>
          <Dropdown
            name="forma_pagto_id"
            control={control}
            label="Forma de Pagamento"
            options={drops?.FormaDePagamento?.filter(
              (f) => f?.modulo !== 'VENDAS' && f?.modulo !== 'CHEQUES'
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="observacao"
            control={control}
            label="Observação"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ParcelaModal = () => {
    const defaultValues = {
      documento_id: documento?.id,
      condicao_pagamento_id: null,
      forma_pagto_id: null,
      posterga: true,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    const onConfirm = (Financeiros) => onSubmit({ Financeiros });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="condicao_pagamento_id"
            control={control}
            label="Condição de Pagamento"
            options={drops?.CondicaoPagamento?.filter((c) => {
              if (documento?.Especie?.grupo === 'ENTRADA') {
                return c?.modulo !== 'VENDAS';
              } else {
                return c?.modulo !== 'COMPRAS';
              }
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="forma_pagto_id"
            control={control}
            label="Forma de Pagamento"
            options={drops?.FormaDePagamento?.filter(
              (f) => f?.modulo !== 'VENDAS' && f?.modulo !== 'CHEQUES'
            )}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const Acordo = () => {
    const options = [
      {
        name: 'Adicionar Parcela',
        icon: 'add',
        action: () => openDialog(<Modal />, 'Adicionar Parcela'),
      },
      {
        name: 'Gerar parcelas',
        icon: 'add',
        action: () => openDialog(<ParcelaModal />, 'Gerar parcelas'),
      },
    ];
    return (
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
        )}
        {Boolean(documento?.DocumentoFinanceiros?.length) && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Parcela</TableCell>
                    <TableCell>Vencimento</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell align="center">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documento?.DocumentoFinanceiros?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.parcela}</TableCell>
                      <TableCell>
                        {Boolean(item?.dtvenc) &&
                          moment(item?.dtvenc).isValid() &&
                          moment(item?.dtvenc).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {item?.valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>{item?.FormaPagto?.descricao}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Duplicata" placement="left">
                          <IconButton
                            size="small"
                            onClick={() => onSubmitDuplicata(item?.id)}
                          >
                            <Print />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Gerar Despesa" placement="left">
                          <IconButton
                            size="small"
                            onClick={() => onSubmitDespesa(item)}
                          >
                            <RequestQuote />
                          </IconButton>
                        </Tooltip>
                        {!disableActions && (
                          <Tooltip title="Deletar" placement="top">
                            <IconButton
                              size="small"
                              onClick={() => onDelete({ rotina, id: item?.id })}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!disableActions && (
                          <Tooltip title="Editar" placement="right">
                            <IconButton
                              size="small"
                              onClick={() =>
                                openDialog(
                                  <Modal item={item} />,
                                  'Editar Parcela'
                                )
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Total:{' '}
            {(
              summarizer(documento?.DocumentoFinanceiros, 'valor') || 0
            )?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const Realizado = () => {
    const Historicos = [];
    documento?.DocumentoFinanceiros?.map((d) =>
      d?.Financeiros?.map((f) =>
        f?.fh?.map((h) => {
          Historicos.push({ ...h, parcela: f?.parcela });
        })
      )
    );

    return (
      <Grid container spacing={2}>
        {Boolean(Historicos?.length) && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Data do Lançamento</TableCell>
                    <TableCell>Parcela</TableCell>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Conta</TableCell>
                    <TableCell>Movimento</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell>Data / Hora</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Historicos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>
                        {Boolean(item?.dtlanc) &&
                          moment(item?.dtlanc).isValid() &&
                          moment(item?.dtlanc).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>{item?.parcela}</TableCell>
                      <TableCell>{item?.FormaPagto?.descricao}</TableCell>
                      <TableCell>
                        {item?.valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>{item?.conta}</TableCell>
                      <TableCell>{item?.financeiro_movimento_id}</TableCell>
                      <TableCell>{item?.User?.name}</TableCell>
                      <TableCell>
                        {Boolean(item?.datahora) &&
                          moment(item?.datahora).isValid() &&
                          moment(item?.datahora).format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Total:{' '}
            {(summarizer(Historicos, 'valor') || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card style={styles?.card}>
          <Tabs value={tab} onChange={(_, v) => setTab(v)} variant="fullWidth">
            <Tab label="Acordo" />
            <Tab label="Realizado" />
          </Tabs>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="Financeiro" style={styles?.card}>
          {tab === 0 && <Acordo />}
          {tab === 1 && <Realizado />}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Financeiros;
