import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import Button from 'components/Button';
import api from 'services/api';

const Haver = ({
  drops,
  valor,
  onSubmit,
  isCheque,
  cadastro_id,
  isPagamento,
  isRecebimento,
}) => {
  const defaultValues = {
    valor: valor || '',
    forma_pagto_id: null,
    haver: '',
    loading: false,
  };
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });
  const drop = drops?.FormaDePagamento?.filter((f) => {
    if (isPagamento) {
      return (
        f?.modalidade === '05-CRÉDITO LOJA' &&
        f?.modulo !== 'VENDAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isRecebimento) {
      return (
        f?.modalidade === '05-CRÉDITO LOJA' &&
        f?.modulo !== 'COMPRAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isCheque) {
      return f?.modalidade === '05-CRÉDITO LOJA' && f?.modulo === 'CHEQUES';
    } else {
      return f?.modalidade === '05-CRÉDITO LOJA';
    }
  });

  const onAddLancamento = ({ forma_pagto_id, valor }) => {
    if (!Boolean(forma_pagto_id)) {
      return toastWarning('Selecione uma forma de pagamento');
    }
    if (Boolean(valor <= 0)) {
      return toastWarning('Valor inválido');
    }
    const data = {
      valor,
      forma_pagto_id,
      forma_pagto: findOnArray(forma_pagto_id, drop, 'label'),
    };
    onSubmit(data);
  };

  const loadHaver = async () => {
    try {
      setValue('loading', true);
      setValue('haver', '');
      const { data } = await api.get(`/Financeiro/Pendencias/HaverDisponivel`, {
        params: { cadastro_id, especie_id: isRecebimento ? 21 : 22 },
      });
      setValue('haver', data?.vlrhaver);
    } catch (error) {
    } finally {
      setValue('loading', false);
    }
  };

  useEffect(() => {
    loadHaver();
  }, []);

  if (watch('loading')) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drop}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="primary" align="center">
          Haver Disponível:{' '}
          {(watch('haver') || 0)?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onAddLancamento)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default Haver;
