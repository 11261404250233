import React, { useContext } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Icon,
} from '@mui/material';
import Button from 'components/Button';
import { useModal } from 'components/Modals';
import { GridContext } from 'contexts/GridContext';

const DeleteModal = ({ onSubmit }) => {
  const { closeModal } = useModal();
  const { deleteLoading: gridLoading } = useContext(GridContext);

  const loading = gridLoading;
  return (
    <>
      <DialogTitle>Confirmar Exclusão</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText>
          <Icon sx={{ fontSize: 150 }}>delete_forever</Icon>
        </DialogContentText>
        <DialogContentText>
          Deseja realmente excluir o registro selecionado?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} loading={loading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteModal;
